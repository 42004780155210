import React, { useState } from 'react';
import Button from 'components/inputs/ButtonM';
import { useMutation, queryCache } from 'react-query';
import { useBranchId, useSnackbar } from 'utils/customHooks';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import { saveAs } from 'file-saver';
import { RenderButton } from 'wms/bins/bins';
import fetch from 'utils/fetch';

export default function OrderBasedSortCreate(props) {
  const { onCancelPressed } = props;
  const nodeId = useBranchId();
  const [notif] = useSnackbar();
  const [statisticsData, setStatisticsData] = useState();

  const [downloadSample] = useMutation(
    () =>
      fetch({
        url: `/wms-sorting/order/session/template?nodeId=${nodeId}`,
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': nodeId,
        },
      }),
    {
      onSuccess: (data) => {
        saveAs(data?.data, data.headers.filename);
      },
      onError: () => {
        notif('Download failed', { variant: 'error', autoHideDuration: 1000 });
      },
    }
  );

  return (
    <div className={'h-auto flex flex-col'}>
      <div className="flex flex-row space-x-2 m-5 justify-center items-center">
        <div>
          <RenderButton
            onClick={downloadSample}
            button={{ name: 'Download Sample', className: 'h-8 font-bold' }}
          />
        </div>
        <Uploader
          id={'sort_order_session'}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          url={`/wms-sorting/order/session?nodeId=${nodeId}`}
          headers={{ 'x-wh-id': nodeId }}
          onSuccess={() => {
            notif('Uploaded successfully', { variant: 'success' });
            queryCache.refetchQueries(['sortSessionList']);
            onCancelPressed();
          }}
          render={(progress, apiInProgress) => (
            <DefaultUploaderRender
              tooltip={'Create Session'}
              progress={progress}
              apiInProgress={apiInProgress}
              icon={
                <RenderButton button={{ name: 'Create Session', className: 'h-8 font-bold' }} />
              }
            />
          )}
        />
        <Uploader
          id={'sort_order_session_statistics'}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          url={`/wms-sorting/order/session/statistics?nodeId=${nodeId}`}
          headers={{ 'x-wh-id': nodeId }}
          onSuccess={(resp) => {
            if (resp) setStatisticsData(resp?.ptl || {});
            notif('Uploaded successfully', { variant: 'success' });
          }}
          render={(progress, apiInProgress) => (
            <DefaultUploaderRender
              tooltip={'Get Statistics'}
              progress={progress}
              apiInProgress={apiInProgress}
              icon={
                <RenderButton button={{ name: 'Get Statistics', className: 'h-8 font-bold' }} />
              }
            />
          )}
        />
      </div>
      {/* Statistics section */}
      {statisticsData ? (
        <div className="container mt-5 p-2 rounded-md md:rounded-md bg-white shadow-md md:shadow-md">
          <p className="mb-2 text-base md:text-lg font-semibold md:font-semibold">SBL Demand</p>
          <div className="flex flex-row flex-grow">
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Total waves required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.waveCount || 0}
              </p>
            </div>
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Total bins required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.waves?.reduce((total, obj) => obj.slotCount + total, 0) || 0}
              </p>
            </div>
          </div>
          <div className="flex flex-row flex-grow">
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Final wave bins required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.waves[statisticsData?.waves.length - 1]?.slotCount || 0}
              </p>
            </div>
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Final wave empty bins :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.availableSlotCount -
                  statisticsData?.waves[statisticsData?.waves.length - 1]?.slotCount || 0}
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-row space-x-2 m-5 justify-center items-center">
        <Button onClick={() => onCancelPressed()}>Cancel</Button>
      </div>
    </div>
  );
}
