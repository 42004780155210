import Button from 'components/inputs/ButtonM';
import DateTimePicker from 'components/inputs/DateTimePicker';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';

export default function SortSessionDialogContent(props) {
  const {
    tripTitle = 'Select your trips',
    tripList,
    statisticsData,
    onDateChanged,
    onChangeTrips,
    onCreateSessionCLicked,
    onCancelPressed,
  } = props;
  const [selectedTrips, setSelectedTrips] = useState([]);

  const tripOptions = useMemo(
    () =>
      tripList.map((item) => {
        return { value: item.id, label: `${item.date}( ${item.name} )` };
      }),
    [tripList]
  );

  return (
    <div className={`${tripOptions?.length >= 5 ? 'h-screen' : 'h-auto'} flex flex-col`}>
      <DateTimePicker
        twclass="sm:w-5/12 md:w-4/12 lg:w-4/12 xl:w-4/12"
        onChange={(dateRange) => onDateChanged(dateRange)}
        maxDays={30}
      />

      {tripOptions?.length ? (
        <Select
          className="mt-5"
          placeholder={tripTitle}
          isMulti
          closeMenuOnSelect={false}
          options={tripOptions}
          onChange={(array) => {
            setSelectedTrips(array);
            onChangeTrips && onChangeTrips(array);
          }}
        />
      ) : null}

      {/* Statistics section */}
      {tripOptions?.length && selectedTrips?.length && statisticsData ? (
        <div className="container mt-5 p-2 rounded-md md:rounded-md bg-white shadow-md md:shadow-md">
          <p className="mb-2 text-base md:text-lg font-semibold md:font-semibold">SBL Demand</p>
          <div className="flex flex-row flex-grow">
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Total waves required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.totalWaves || 0}
              </p>
            </div>
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Total bins required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.totalBins || 0}
              </p>
            </div>
          </div>
          <div className="flex flex-row flex-grow">
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Final wave bins required :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.finalWavesBins || 0}
              </p>
            </div>
            <div className="flex flex-row flex-grow m-1">
              <p className="text-sm md:text-sm font-normal md:font-normal">
                Final wave empty bins :{' '}
              </p>
              <p className="text-sm md:text-sm font-medium md:font-medium">
                {statisticsData?.totalWavesEmptyBins || 0}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {tripOptions?.length ? (
        <div className="flex flex-row space-x-2 m-5 justify-center items-center">
          <Button
            onClick={() => {
              const ids = selectedTrips.map((item) => item.value);
              onCreateSessionCLicked(ids);
            }}
          >
            Create Session
          </Button>
          <Button onClick={() => onCancelPressed()}>Cancel</Button>
        </div>
      ) : (
        <p className="mt-10 text-center">No trips available</p>
      )}
    </div>
  );
}
