import { MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  useLoadingTasks,
  useMMLoadingAssignWorker,
  useMMLoadingLoadAll,
  useWMSWorkers,
} from 'apiHooks/wmsHooks';
import { Download as DownloadIcon } from 'components/Icons';
import { WrapInLink } from 'components/Link';
import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime, getDateRange } from 'utils/utils';
import { AssignWorkerDialogContent } from 'wms/ira/IraDetails';
import { Status } from 'wms/molecules/status';
import WmsFilters from 'wms/WmsFilters';
import { UnassignConfirmation as Confirmation } from 'xdock/TaskManagement';
import { EwaybillDialogContent } from './EwaybillContent';

const initialDates = getDateRange(new Date(), 1);

export const AssignType = {
  assign_worker: 'assign_worker',
  unassign_worker: 'unassign_worker',
};

export default function LoadingTasks() {
  const branchId = useBranchId();
  const [modalState, setModalState] = useState({ eWaybillDialog: false });
  const [dateRange, setDateRange] = useState(initialDates);
  const { data: tasks, isFetching } = useLoadingTasks({
    branchId,
    createdAtWindowStart: dateRange[0],
    createdAtWindowEnd: dateRange[1],
    planDateWindowStart: dateRange[0],
    planDateWindowEnd: dateRange[1],
  });

  // memoized
  const sortedTasks = useMemo(
    () => tasks?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [],
    [tasks]
  );
  const tripList = useMemo(
    () =>
      sortedTasks.map((t) => ({
        id: t.id,
        lmTrip: t.attrs.lmTripCode,
        mmTrip: t.attrs.mmTripCode,
      })),
    [sortedTasks]
  );

  const showEwayBill = () => setModalState((p) => ({ ...p, eWaybillDialog: true }));
  const hideEWayBilDialog = useCallback(
    () => setModalState((p) => ({ ...p, eWaybillDialog: false })),
    []
  );

  const onDateFilterChanged = useCallback((dates) => {
    const newDates = dates.length ? dates : initialDates;
    setDateRange(newDates);
  }, []);

  return (
    <div>
      {isFetching && <LinearProgress classes={{ colorPrimary: 'bg-green-300' }} />}

      <div className="flex flex-col w-full h-full p-5">
        <div className="flex flex-row mt-5 mx-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold">
              MM Loading Tasks
            </p>
          </div>
          <WmsFilters
            onFiltersChange={onDateFilterChanged}
            onFilterCancelled={() => onDateFilterChanged([])}
          />
        </div>
        <TasksComponent tasks={sortedTasks} />
      </div>

      <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<DownloadIcon />}
          onClick={showEwayBill}
        >
          Download E-way bill
        </Button>
      </div>

      <ModalComponent show={modalState.eWaybillDialog} onHide={hideEWayBilDialog}>
        <EwaybillDialogContent tripList={tripList || []} />
      </ModalComponent>
    </div>
  );
}

function ModalComponent({ show, onHide, children }) {
  return (
    <Modal size="md" overflow={true} show={show} onHide={onHide}>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

function TasksComponent({ tasks }) {
  const [task, setTask] = useState('');
  const branchId = useBranchId();
  const [assignApiType, setAssignApiType] = useState('');
  const onCheckboxClicked = (id) => setSelectedWorkers((p) => ({ ...p, [id]: !p[id] }));

  const [modalState, setModalState] = useState(false);

  const [selectedWorkers, setSelectedWorkers] = useState({});
  const { data: workers, status } = useWMSWorkers({ branchId });
  const [assignWorker] = useMMLoadingAssignWorker({
    branchId,
    onSuccess: () => setSelectedWorkers({}),
  });

  const assignWorkerConfirmed = () => {
    const workerIds = workers?.filter((w) => !!selectedWorkers[w.id]).map((w) => w.id);
    assignWorker({ taskId: task.id, workerIds, apiType: assignApiType });
    setModalState(false);
  };

  const workerList = useMemo(() => {
    const ws =
      assignApiType === AssignType.assign_worker
        ? workers?.filter((w) => !task?.assignedWorkers?.find((aw) => aw.id === w.id))
        : workers?.filter((w) => task?.assignedWorkers?.find((aw) => aw.id === w.id));
    return ws?.map((w) => ({ ...w, isChecked: !!selectedWorkers[w.id] }));
  }, [assignApiType, selectedWorkers, task, workers]);

  const assignOrUnassign = useCallback((task, apiType) => {
    setAssignApiType(apiType);
    setTask(task);
    setModalState(true);
  }, []);

  const hideAssignDialog = useCallback(() => setModalState(false), []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3">
      {!tasks?.length && (
        <div className="mx-5 my-10">
          <p className="text-medium">No data found</p>
        </div>
      )}
      {tasks?.map((task) => (
        <MemoizedTaskItem
          key={task.id}
          task={task}
          navigateTo={`/wms/outbound/loading/details/${task.id}`}
          onClick={assignOrUnassign}
        />
      ))}
      <ModalComponent show={modalState} onHide={hideAssignDialog}>
        <AssignWorkerDialogContent
          title={`Select workers to ${
            assignApiType === AssignType.assign_worker ? 'assign' : 'unassign'
          } for loading task`}
          loading={status === 'loading'}
          workerList={workerList}
          onCheckboxClicked={onCheckboxClicked}
          onAssignWorkerConfirmed={assignWorkerConfirmed}
          onCancel={hideAssignDialog}
        />
      </ModalComponent>
    </div>
  );
}

const MemoizedTaskItem = React.memo(TaskItem, (prevProps, nextProps) => {
  const pt = prevProps.task;
  const nt = nextProps.task;
  pt.state === nt.state || pt.assignedWorkers.length === nt.assignedWorkers.length;
});

export function TaskItem(props) {
  const { task, onClick, navigateTo } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadAllModal, setLoadAllModal] = useState(false);
  const branchId = useBranchId();

  const [loadAll] = useMMLoadingLoadAll({ branchId });

  const onLoadAll = () => {
    loadAll({ taskId: task?.id || '' });
    setAnchorEl(null);
    setLoadAllModal(false);
  };

  const isTaskCompleted = task.state === 'COMPLETED';
  const workers = task.assignedWorkers;
  const isDisabled = task.disableLoadAllInMMLoading;

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        isTaskCompleted ? 'border-success-green' : 'border-background'
      } m-3 p-3`}
    >
      <div className="flex flex-row">
        <div className="flex flex-3 flex-col flex-grow">
          <WrapInLink uri={navigateTo}>
            <p className="text-base md:text-base font-bold md:font-bold">
              MM : {task?.attrs?.mmTripCode}
            </p>
            <p className="text-base md:text-base font-bold md:font-bold">
              LM : {task?.attrs?.lmTripCode}
            </p>
            <div className="flex flex-col">
              <p className="text md:text-xs font-medium md:font-medium mt-2">
                {formatDateTime(new Date(task?.createdAt))}
              </p>
              <p className="mt-1 text md:text-xs font-medium md:font-medium">{task?.code}</p>
              <p className="mt-1 text md:text-xs font-medium md:font-medium">
                {task?.sortSessions.join(', ')}
              </p>
              <p className="text md:text-xs font-medium md:font-medium mt-2">
                <Status state={task?.state} />
              </p>
              {workers && (
                <div className="mt-3">
                  {workers.map((w) => (
                    <Chip
                      key={w.id}
                      clickable={false}
                      avatar={<Avatar alt={w.name || ''} />}
                      label={`${w.name || ''}`}
                      className="mr-1 my-1"
                    />
                  ))}
                </div>
              )}
            </div>
          </WrapInLink>
          <Button
            variant="contained"
            color="primary"
            className="mt-3 self-start py-1 px-4"
            onClick={() => onClick(task, AssignType.assign_worker)}
          >
            Assign Worker
          </Button>
        </div>
        <Confirmation
          title={`This will change the status of all HUs in this task to 'LOADED'.\nAre you sure?`}
          open={loadAllModal}
          onYes={() => onLoadAll()}
          close={() => setLoadAllModal(false)}
        />
        <div className="flex-1 flex-grow">
          <div className="float-right">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setLoadAllModal(true);
                  setAnchorEl(null);
                }}
                disabled={isDisabled}
              >
                Load all
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  onClick(task, AssignType.unassign_worker);
                }}
              >
                Unassign Worker
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
