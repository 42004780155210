import { useOutboundTaskComplete, useOutboundTasks } from 'apiHooks/wmsHooks';
import { useState } from 'react';
import { useBranchId } from 'utils/customHooks';
export const useOutboundTaskLogic = ({ kind }) => {
  const branchId = useBranchId();
  const { data } = useOutboundTasks({ branchId, kind });
  const [taskId, setTaskId] = useState('');
  const [outboundComplete] = useOutboundTaskComplete({ branchId });

  const completeTask = ({ taskId }) => {
    outboundComplete({ taskId, kind });
  };

  const tasks = data
    // ?.reverse()
    ?.sort((a, b) => a.progress.done / a.progress.target - b.progress.done / b.progress.target);

  return { tasks, taskId, completeTask, setTaskId };
};
