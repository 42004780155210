import Button from 'components/inputs/ButtonM';
import React from 'react';
import { PaginatedList } from 'wms/molecules/PaginatedList';
import { Status } from 'wms/molecules/status';
import { UnassignConfirmation } from 'xdock/TaskManagement';
import { useOutboundTaskLogic } from './hooks';

export default function Outbound2Tasks({ label, kind }) {
  const { tasks, taskId, completeTask, setTaskId } = useOutboundTaskLogic({ kind });

  const tripPrefix = label === 'Sorting' ? 'Trips ' : '';

  const getTrip = (task) => {
    if (task.attrs.strat === 'PICK_ALL_TRIPS') return `${tripPrefix}Multiple`;

    return task.attrs.lmTripCode;
  };
  return (
    <div>
      <div className="flex flex-col w-full h-full p-5">
        <div className="flex-1">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            {label} Tasks Status
          </p>
        </div>
        <PaginatedList
          numberOfItemsOnPage={12}
          dataList={tasks}
          renderItem={(task) => (
            <OutboundTaskItem key={task.id} task={task} getTrip={getTrip} setTaskId={setTaskId} />
          )}
        />
        <UnassignConfirmation
          title={'This will force complete the task. Do you really want to go proceed?'}
          open={!!taskId}
          onYes={() => completeTask({ taskId })}
          close={() => setTaskId('')}
        />
      </div>
    </div>
  );
}

export function OutboundTaskItem(props) {
  const { task, setTaskId, getTrip } = props;

  const isTaskCompleted = task.progress.done === task.progress.target;

  const Labeled = ({ label, val }) => {
    if (!val) return null;
    return (
      <p className="text md:text-sm font-medium md:font-medium mr-2">
        {label}: {val}
      </p>
    );
  };

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        isTaskCompleted ? 'border-success-green' : 'border-background'
      } m-5 p-3`}
    >
      <div className="flex flex-col flex-1">
        <div className="flex flex-row justify-between">
          <p className="text-base md:text-base font-bold md:font-bold">{getTrip(task)}</p>
        </div>
        <div className="flex flex-col mt-2">
          <p className="text-sx md:text-sx font-medium">{task.code}</p>
          <div className="flex">
            <Labeled label={'Class'} val={task.attrs.skuClass} />
            <Labeled label={'UOM'} val={task.attrs.uom} />
          </div>
          <p className="text md:text-xs font-medium md:font-medium mt-2">
            <Status state={task?.state} />
          </p>
        </div>
      </div>

      {task.state !== 'COMPLETED' && (
        <Button className="mt-3 h-8 rounded-md" onClick={() => setTaskId(task.id)}>
          Complete
        </Button>
      )}
    </div>
  );
}
