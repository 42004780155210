import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useState } from 'react';
import Select from 'react-select';
import { Nav } from 'rsuite';
import { getDateRange } from 'utils/utils';
import { ForceComplete } from 'wms/molecules/ForceComplete';
import WmsFilters from 'wms/WmsFilters';
import { CreatePickingSession } from '../picking/picking';
import { useCockpitTabs } from './useCockpitTabs';

const initialDates = getDateRange(new Date(), 7);
export default function Cockpit() {
  const [dateRange, setDateRange] = useState(initialDates);
  const onDateFilterChanged = (dates) => {
    setDateRange(dates.length ? dates : initialDates);
  };
  //
  const {
    tabConfigs,
    tripList,
    isFetching,
    activeKey,
    dialogVisibility,
    session,
    onSelectSession,
    hideDialog,
    onChangeTab,
  } = useCockpitTabs({ dateRange });

  const {
    sessions: sessionOptions,
    buttonConfig,
    tripsComponent,
    createSessionComponent,
  } = tabConfigs[activeKey];

  return (
    <div className="flex flex-col flex-1">
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}

      <div className="flex flex-1 flex-col pt-5 mx-1 md:mx-6 lg:mx-10">
        <div className="flex justify-center">
          <div className="flex flex-1">
            <Nav justified activeKey={activeKey} onSelect={onChangeTab} appearance="tabs">
              <Nav.Item eventKey={0}>
                <p className="flex justify-center">Inbound</p>
              </Nav.Item>
              <Nav.Item eventKey={1}>
                <p className="flex justify-center">Outbound</p>
              </Nav.Item>
              <Nav.Item eventKey={2}>
                <p className="flex justify-center">Returns</p>
              </Nav.Item>
            </Nav>
          </div>
          <div className="flex items-center">{createSessionComponent}</div>
          <div className="ml-5">
            <WmsFilters
              onFiltersChange={onDateFilterChanged}
              onFilterCancelled={() => onDateFilterChanged([])}
            />
          </div>
        </div>
        {tripsComponent}
        <div className="flex flex-row w-screen fixed bottom-3 items-center">
          <Select
            options={sessionOptions}
            onChange={onSelectSession}
            value={session}
            placeholder="Select Session"
            menuPlacement="top"
            className={'w-60 mr-3'}
            isSearchable={true}
          />
          {buttonConfig && (
            <div className="items-start">
              <ForceComplete
                isFixed={false}
                title={buttonConfig.title}
                isTaskCompleted={!session.value}
                onForceComplete={buttonConfig.onClick}
                warningMessage={`Are you sure you want to Force ${buttonConfig.title}? You will not be able to make any changes after this.`}
              />
            </div>
          )}
        </div>
      </div>
      <CreatePickingSession
        {...{
          tripTitle: 'Select your Trips or Orders',
          tripList,
          dialogVisibility,
          hideDialog,
          onDateFilterChanged,
        }}
      />
    </div>
  );
}
