import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Pagination from '@material-ui/lab/Pagination';
import { useCreatePickingSession, usePickingSession, usePickingTrips } from 'apiHooks/wmsHooks';
import Link from 'components/Link';
import React, { useMemo, useState } from 'react';
import { Modal } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime, getDateRange } from 'utils/utils';
import SortSessionDialogContent from 'wms/outbound/sorting/SortSessionDialogContent';
import WmsFilters from 'wms/WmsFilters';

const initialDates = getDateRange(new Date(), 7);

export default function PickingSessions() {
  const branchId = useBranchId();
  const [startDate, setStartDate] = useState(initialDates[0]);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [endDate, setEndDate] = useState(initialDates[1]);
  const [activePage, setActivePage] = useState(1);
  // const [tripIds, setTripIds] = useState([]);

  const offset = useMemo(() => (activePage - 1) * 12, [activePage]);
  const handlePageChange = (e, value) => setActivePage(value);
  const hideDialog = () => setDialogVisibility(false);

  function onDateFilterChanged(dates) {
    const newDates = dates.length ? dates : initialDates;

    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  }

  const { data, isFetching } = usePickingSession({ branchId, startDate, endDate });
  const { data: tripList } = usePickingTrips({ nodeId: branchId, startDate, endDate });
  // const { data: statistics } = usePickingStats({ nodeId: branchId, tripIds });

  const sessionList = data || [];
  // const sessionList = data?.filter((t) => t.state !== 'COMPLETED') || [];

  return (
    <div>
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}

      <div className="flex flex-col w-full h-full">
        {/* Header Section containing Filters */}
        <div className="flex flex-row m-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              Picking Session List
            </p>
          </div>
          <WmsFilters
            onFiltersChange={onDateFilterChanged}
            onFilterCancelled={() => onDateFilterChanged([])}
          />
        </div>
        {/* Session List Section */}
        <div className="grid grid-cols-1 md:grid-cols-4">
          {sessionList?.slice(offset, offset + 12).map((item) => (
            <SessionItem key={item?.id} session={item} />
          ))}
        </div>

        {/* Create sorting session fab button */}
        <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
          <Fab color="primary" aria-label="add" onClick={() => setDialogVisibility(true)}>
            <AddIcon />
          </Fab>
        </div>

        {/* Pagination section */}
        <div className="mt-5 m-auto">
          {sessionList?.length / 12 > 1 && (
            <Pagination onChange={handlePageChange} count={Math.ceil(sessionList?.length / 12)} />
          )}
        </div>
      </div>

      {/* create Sort session dialog */}
      <CreatePickingSession {...{ tripList, onDateFilterChanged, dialogVisibility, hideDialog }} />
    </div>
  );
}

function SessionItem(props) {
  const { session } = props;

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <Link to={`/wms/outbound/picking/tasks/${session?.id}`}>
        <div className="flex-2 flex-col flex-grow">
          <p className="text-base md:text-base font-bold md:font-bold m-1">{session?.code}</p>
          <div className="flex flex-col">
            <p className="text-xs md:text-xs font-medium md:font-medium m-1">
              {formatDateTime(new Date(session?.createdAt))}
            </p>
            <p className="text-xs md:text-xs font-medium md:font-medium ml-1">{session?.state}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export const CreatePickingSession = ({
  tripTitle,
  tripList,
  dialogVisibility,
  hideDialog,
  onDateFilterChanged,
}) => {
  const branchId = useBranchId();
  const [createSortingSession] = useCreatePickingSession({ nodeId: branchId });

  return (
    <Modal size="sm" overflow={true} show={dialogVisibility} onHide={hideDialog}>
      <Modal.Body>
        <SortSessionDialogContent
          tripTitle={tripTitle}
          tripList={tripList || []}
          onDateChanged={onDateFilterChanged}
          onCreateSessionCLicked={(ids) => {
            createSortingSession({ tripIds: ids });
            hideDialog();
          }}
          onCancelPressed={hideDialog}
        />
      </Modal.Body>
    </Modal>
  );
};
