import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import fetch from 'utils/fetch';
import { Upload } from './Icons';

const withSpan = (Component) => <Component.type component="span" {...Component.props} />;

export default function Uploader({
  render,
  url,
  onSuccess,
  headers,
  onError = (err) => console.log(err), // eslint-disable-line
  method = 'post',
  id = 'text-button-file',
  disabled,
  ...rest
}) {
  const [progress, setProgress] = useState(0);
  const [apiInProgress, setApiInProgress] = useState(false);
  const ref = useRef(null);

  const upload = async (e) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    try {
      const result = await fetch({
        method,
        url,
        headers,
        data,
        onUploadProgress: (progressEvent) => {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          if (progressEvent.loaded === progressEvent.total) setApiInProgress(true);
        },
      });
      onSuccess(result);
    } catch (error) {
      ref.current.value = '';
      onError(error);
    }
    setProgress(0);
    setApiInProgress(false);
  };

  return (
    <>
      <input
        ref={ref}
        type="file"
        id={id}
        style={{ display: 'none' }}
        onChange={upload}
        onClick={(event) => {
          event.target.value = '';
        }}
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={id}>{withSpan(render(progress, apiInProgress))}</label>
    </>
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  iconButton: {
    padding: 2,
    borderRadius: 0,
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const DefaultUploaderRender = ({
  icon,
  tooltip = 'Upload',
  disabled,
  progress = 0,
  apiInProgress = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress
        size={39}
        className={classes.fabProgress}
        variant={apiInProgress ? 'indeterminate' : 'static'}
        value={progress}
      />
      <Tooltip title={tooltip} placement="top">
        <IconButton
          disabled={disabled}
          component="span"
          color="secondary"
          className={classes.iconButton}
        >
          {icon ? icon : <Upload fontSize="large" />}
        </IconButton>
      </Tooltip>
    </div>
  );
};
