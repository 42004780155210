import LinearProgress from '@material-ui/core/LinearProgress';
import { useForceComplete, useSortingSessionData } from 'apiHooks/wmsHooks';
import Button from 'components/inputs/ButtonM';
import React, { useState } from 'react';
import { useBranchId } from 'utils/customHooks';
import { Status } from 'wms/molecules/status';
import { UnassignConfirmation as Confirmation } from 'xdock/TaskManagement';

export const getWaveWiseTasks = (data) => {
  const isValidSortTask = (task) =>
    task.wave != undefined && (task.kind === 'SBL_SORT' || task.kind === 'LISORT_ARM');
  const allTasks = data.flatMap((d) => d.tasks);

  const waveWiseTasks = allTasks.reduce((acc, cur) => {
    if (isValidSortTask(cur)) {
      acc[cur.wave] = [...(acc[cur.wave] || []), cur];
    }
    return acc;
  }, {});
  return waveWiseTasks;
};

export default function SortingTasks(props) {
  const { sessionCode, wave } = props;
  const branchId = useBranchId();
  const { data } = useSortingSessionData({ sessionCode, branchId });

  if (!data) {
    return (
      <LinearProgress
        classes={{
          colorPrimary: 'bg-green-300',
        }}
      />
    );
  }
  const waveWiseTasks = getWaveWiseTasks(data);

  return (
    <div className="container">
      <div className="flex flex-col w-full h-full p-5">
        <div className="flex-1">
          <p className="mx-5 text-base antialiased font-semibold md:text-2xl sm:subpixel-antialiased md:antialiased md:font-semibold">
            Wave {+wave + 1} Tasks Status
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4">
          {waveWiseTasks[wave]?.map((task) => (
            <TaskItem key={task.code} sessionCode={sessionCode} wave={wave} task={task} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function TaskItem(props) {
  const { wave, task } = props;
  const [code, setCode] = useState('');
  const branchId = useBranchId();

  const [forceComplete] = useForceComplete({ branchId });

  const getMetrics = () => {
    return `Done : ${task.progress.done} Pending : ${task.progress.target - task.progress.done}`;
  };

  const isTaskCompleted = task.progress.done === task.progress.target;

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        isTaskCompleted ? 'border-success-green' : 'border-background'
      } m-5 p-3`}
    >
      <div className="flex flex-row">
        <div className="flex flex-col flex-grow">
          <p className="text-base font-bold md:text-base md:font-bold">{task.code}</p>
          <p className="font-bold text-sx md:text-sx">Wave {+wave + 1}</p>
          <div className="flex flex-col">
            <p className="font-medium text md:text-xs md:font-medium">{getMetrics()}</p>
            <p className="mt-2 font-medium text md:text-xs md:font-medium">
              <Status state={task?.state} />
            </p>
          </div>
          {task.state !== 'COMPLETED' && (
            <Button
              className="mt-5 bg-red"
              onClick={() => setCode(task.code)}
            >
              Complete
            </Button>
          )}
        </div>
        <div className="text-right">
          <p className="text-sm font-bold md:text-sm md:font-bold">{task.kind}</p>
          <p className="text-sm font-bold md:text-sm md:font-bold">{task.attrs.liSortType}</p>
        </div>
      </div>
      <Confirmation
        title={'This will force complete the task. Do you really want to go proceed?'}
        open={!!code}
        onYes={() => forceComplete({ code })}
        close={() => setCode('')}
      />
    </div>
  );
}
