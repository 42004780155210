import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import React from 'react';
import { useBranchId, useSnackbar } from 'utils/customHooks';
import { useBinUploads } from './useBinUploads';

export default function BinUploads() {
  const { uploadList, isFetching } = useBinUploads();
  return (
    <div>
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}

      <div className="flex flex-col h-full pt-5">
        {/* Session List Section */}
        {uploadList
          .filter((x) => !x.disabled)
          .map((item) => (
            <Item key={item.name} {...{ item }} />
          ))}
      </div>
    </div>
  );
}

function Item(props) {
  const { item } = props;

  const branchId = useBranchId();
  const [notif] = useSnackbar();
  const { download } = useBinUploads();

  return (
    <div className="flex mx-5 py-3">
      <div className="flex flex-1 mx-2 flex-col md:flex-row">
        <div className="flex flex-1">
          <p className="text-base md:text-base font-bold md:font-bold my-1">{item.name}</p>
        </div>
        <div className="flex flex-1 flex-col md:flex-row content-start">
          {item.buttons.map((b) => (
            <div key={b.name + b.isPost} className="flex">
              {b.isPost ? (
                <Uploader
                  id={item.url + b.type}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  url={
                    item.name === 'Outbound Priority'
                      ? `bwms/${item.url}${b.type}?nodeId=${branchId}`
                      : item.name === 'Parking Structure'
                      ? `wms/yms/${item.url}${b.type}`
                      : `${item.url}${b.type}`
                  }
                  headers={{ 'x-wh-id': branchId }}
                  onSuccess={() => notif('Uploaded successfully', { variant: 'success' })}
                  disabled={item.disabled}
                  render={(progress, apiInProgress) => (
                    <DefaultUploaderRender
                      tooltip={b.name}
                      progress={progress}
                      apiInProgress={apiInProgress}
                      disabled={item.disabled}
                      icon={<RenderButton button={b} disabled />}
                    />
                  )}
                />
              ) : (
                <RenderButton
                  button={b}
                  disabled={item.disabled}
                  onClick={() => download({ url: item.url + b.type })}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const RenderButton = ({ button, onClick, disabled }) => {
  return (
    <div
      className={`flex border rounded-md border-gray-400 items-center p-0 ml-0 mt-2 md:ml-2 md:mt-0`}
    >
      <IconButton
        onClick={onClick}
        disabled={disabled}
        component="span"
        className={`${button.className} text-base rounded-none px-2 py-1 content-start`}
      >
        {button.name}
        {button.icon && <span className="ml-3">{button.icon}</span>}
      </IconButton>
    </div>
  );
};
