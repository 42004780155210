import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import Cockpit from 'wms/outbound/cockpit/cockpit';
import LoadingDetails from 'wms/outbound/loading/details';
import LoadingTasks from 'wms/outbound/loading/loading';
import Loading2Tasks from 'wms/outbound/loading/tasks2';
import PickingSessions from 'wms/outbound/picking/picking';
import PickingTasks from 'wms/outbound/picking/tasks';
import Picking2Tasks from 'wms/outbound/picking/tasks2';
import { SortingDashboard } from 'wms/outbound/sorting/Dashboard';
import Sorting from 'wms/outbound/sorting/Sorting';
import SortingTasks from 'wms/outbound/sorting/SortingTasks';
import SortingWaves from 'wms/outbound/sorting/SortingWaves';
import Sorting2Tasks from 'wms/outbound/sorting/tasks2';

export default function PickingContainer(props) {
  const basePath = '/wms/outbound';

  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background min-h-full max-h-auto">
          <Router>
            <PickingSessions path={`${basePath}/picking`} />
            <PickingTasks path={`${basePath}/picking/tasks/:sessionId`} />
            <Picking2Tasks path={`${basePath}/2/picking`} />
            {/* <PickingTasks path={`${basePath}/2/picking/tasks/:sessionId`} /> */}

            <Sorting path={`${basePath}/sorting`} />
            <SortingWaves path={`${basePath}/sorting/tasks/:sessionCode`} />
            <SortingTasks path={`${basePath}/sorting/tasks/:sessionCode/:wave`} />
            <Sorting2Tasks path={`${basePath}/2/sorting`} />
            <SortingDashboard path={`${basePath}/sorting/dashboard`} />
            {/* <SortingWaves path={`${basePath}/2/sorting/tasks/:sessionCode`} />
            <SortingTasks path={`${basePath}/2/sorting/tasks/:sessionCode/:wave`} /> */}

            <LoadingTasks path={`${basePath}/loading`} />
            <LoadingDetails path={`${basePath}/loading/details/:taskId`} />
            <Loading2Tasks path={`${basePath}/2/loading`} />
            {/* <LoadingDetails path={`${basePath}/2/loading/details/:taskId`} /> */}

            {/* <Cockpit path={`${basePath}/cockpit`} /> */}
            <Cockpit path={`${basePath}/2/cockpit`} />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
