import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { usePickingAssignWorker, usePickingSessionDetails, useWMSWorkers } from 'apiHooks/wmsHooks';
import React, { useState } from 'react';
import { Modal } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime } from 'utils/utils';
import { Status } from 'wms/molecules/status';
import { AssignWorkerDialogContent } from 'wms/ira/IraDetails';

export default function PickingTasks(props) {
  const { sessionId } = props;
  const branchId = useBranchId();
  const [taskId, setTaskId] = useState('');
  const [modalState, setModalState] = useState({ assignWorkerDialogVisibility: false });

  const [selectedWorkers, setSelectedWorkers] = useState({});

  const { data, isFetching } = usePickingSessionDetails({
    branchId,
    sessionId,
  });
  const { data: workers, status } = useWMSWorkers({ branchId });
  const [assignWorker] = usePickingAssignWorker({
    branchId,
    sessionId,
    onSuccess: () => setSelectedWorkers({}),
  });

  const assignWorkerConfirmed = () => {
    const workerId = workers?.find((w) => !!selectedWorkers[w.id]).id;
    assignWorker({ taskId, workerId });
    setModalState((p) => ({ ...p, assignWorkerDialogVisibility: false }));
  };

  return (
    <div>
      {isFetching === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex flex-col w-full h-full p-5">
        <div className="flex-1">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            {data?.code} Tasks Status
          </p>
          <div className="grid grid-cols-1 md:grid-cols-4">
            {!data?.tasks && (
              <div className="mx-5 my-10">
                <p className="text-medium">No data found</p>
              </div>
            )}
            {data?.tasks?.map((task) => (
              <TaskItem
                key={task?.id}
                task={task}
                onClick={() => {
                  setModalState((p) => ({ ...p, assignWorkerDialogVisibility: true }));
                  setTaskId(task.id);
                }}
              />
            ))}
          </div>
        </div>
      </div>

      <Modal
        size="sm"
        overflow={true}
        show={modalState.assignWorkerDialogVisibility}
        onHide={() => setModalState((p) => ({ ...p, assignWorkerDialogVisibility: false }))}
      >
        <Modal.Body>
          <AssignWorkerDialogContent
            label="Picking"
            loading={status === 'loading' ? true : false}
            workerList={workers?.map((w) => ({
              ...w,
              isChecked: !!selectedWorkers[w.id],
            }))}
            onCheckboxClicked={(id) => setSelectedWorkers((p) => ({ [id]: !p[id] }))}
            onAssignWorkerConfirmed={assignWorkerConfirmed}
            onCancel={() => setModalState((p) => ({ ...p, assignWorkerDialogVisibility: false }))}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function TaskItem(props) {
  const { task, onClick } = props;

  const isTaskCompleted = task.state === 'COMPLETED';
  const worker = task.assignedWorker;

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        isTaskCompleted ? 'border-success-green' : 'border-background'
      } m-5 p-3`}
    >
      <div className="flex flex-row">
        <div className="flex flex-col flex-grow">
          <p className="text-base md:text-base font-bold md:font-bold">{task.code}</p>
          <div className="flex flex-col">
            <p className="text md:text-xs font-medium md:font-medium mt-2">
              {formatDateTime(new Date(task?.createdAt))}
            </p>
            <p className="text md:text-xs font-medium md:font-medium mt-2">
              <Status state={task?.state} />
            </p>
            {worker && (
              <div className="mt-3">
                <Chip
                  key={worker?.id}
                  clickable={false}
                  avatar={<Avatar alt={worker?.name || ''} src="/static/images/avatar/1.jpg" />}
                  label={`${worker?.name || ''} (${worker?.code || ''})`}
                />
              </div>
            )}
          </div>
          {!task.assignedWorker && (
            <Button className="mt-3 bg-theme text-white self-start py-1 px-4" onClick={onClick}>
              Assign Worker
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
