import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { UnassignConfirmation as Confirmation } from 'xdock/TaskManagement';

export function ForceComplete({
  isTaskCompleted,
  onForceComplete,
  title,
  warningMessage,
  isFixed = true,
}) {
  const [visiblity, setVisibility] = useState(false);

  return (
    <div>
      <div className={`${isFixed ? 'fixed' : ''} bottom-3 flex-1 inset-x-1/2`}>
        <Button
          variant="contained"
          color="primary"
          disabled={isTaskCompleted}
          className="whitespace-nowrap"
          onClick={() => setVisibility(true)}
        >
          {title || 'Force Complete'}
        </Button>
      </div>

      <Confirmation
        title={warningMessage || `HUs pending for loading will not be dispatched.\nAre you sure?`}
        open={visiblity}
        onYes={() => onForceComplete()}
        close={() => setVisibility(false)}
      />
    </div>
  );
}
