import Pagination from '@material-ui/lab/Pagination';
import { gridCols } from 'components/layouts/Container';
import React, { useMemo, useState } from 'react';
import { Panel, PanelGroup } from 'rsuite';
import { decodeSessionCode, format0 } from 'utils/utils';
import { stateToIcon } from './icons';
export function Trips({ title, trips }) {
  if (!trips?.length) return null;

  const cols = [...trips[0].cols.map((c) => c.label)];
  return (
    <div className="mt-5">
      <div className="grid grid-cols-2">
        <p className="text-sm font-bold text-iraText">{title}</p>
        <div className={`grid ${gridCols[cols.length]} gap-2`}>
          {cols.map((c) => (
            <p className="text-sm font-bold text-iraText">{c}</p>
          ))}
        </div>
      </div>
      <PaginatedList
        numOfItems={10}
        list={trips}
        renderItem={(item) => <TripItem {...{ item }} />}
      />
    </div>
  );
}

function PaginatedList({ list, renderItem, numOfItems = 12 }) {
  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * numOfItems, [activePage, numOfItems]);
  const handlePageChange = (e, value) => setActivePage(value);

  return (
    <div>
      {list?.slice(offset, offset + numOfItems).map((x) => (
        <div key={x.id}>{renderItem(x)}</div>
      ))}
      <div className="mt-5 m-auto">
        {list?.length / numOfItems > 1 && (
          <Pagination onChange={handlePageChange} count={Math.ceil(list?.length / numOfItems)} />
        )}
      </div>
    </div>
  );
}
function TripItem(props) {
  const { item } = props;

  const tripAsn = item.asnNo ? (
    <p>
      <span className="font-bold">ASN No: </span>
      {item.asnNo},<span className="font-bold"> Vehicle No: </span>
      {item.tripCode}
    </p>
  ) : (
    `${item.tripCode}`
  );

  const sessionText = (
    <span>
      <span className="font-bold"> Session: </span>
      <span>{decodeSessionCode(item.sessionCode)} </span>
    </span>
  );

  return (
    <PanelGroup
      key={item.tripId}
      accordion
      classPrefix="bg-white grid grid-cols-2 gap-2 items-center mt-1 m-0 md:rounded-md lg:rounded-lg"
    >
      <Panel header={tripAsn} classPrefix="my-0 px-2 py-1 cursor-pointer">
        <p className="text-sm">{sessionText}</p>
      </Panel>
      <div className={`grid ${gridCols[item.cols.length]} gap-2`}>
        {item.cols.map((c) => (
          <div key={item.tripCode + c.label} className="flex items-center">
            <span>{stateToIcon[c.state] || c.state}</span>
            {c.percent !== 'NONE' && (
              <span className="ml-1 font-medium">{format0(c.percent) + '%'}</span>
            )}
          </div>
        ))}
      </div>
    </PanelGroup>
  );
}
