import SvgIcon from '@material-ui/core/SvgIcon';
import BlockIcon from '@material-ui/icons/Block';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ErrorIcon from '@material-ui/icons/Warning';
import React from 'react';

const DiamondIcon = (props) => (
  <SvgIcon
    {...props}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <path d="M443.92,243.452L273.253,8.786c-8.519-11.714-25.987-11.714-34.506,0L68.08,243.452c-5.44,7.481-5.44,17.615,0,25.095    l170.667,234.667c8.519,11.714,25.987,11.714,34.506,0L443.92,268.548C449.36,261.067,449.36,250.933,443.92,243.452z     M256,454.396L111.712,256L256,57.604L400.288,256L256,454.396z" />
    </g>
  </SvgIcon>
);
export const stateToIcon = {
  COMPLETED: (
    <div className="flex w-5 h-4 justify-center">
      <div className="w-3 h-4 rounded-sm bg-greenDarkPh"></div>
    </div>
  ),
  PENDING: <DiamondIcon />,
  ISSUE: <ErrorIcon className="text-issue" />,
  ONGOING: <FiberManualRecordIcon className="text-iraTable" />,
  NONE: <BlockIcon className="text-danger" />,
};
