import React from 'react';

export function Status({ state }) {
  return (
    <span
      className={`${
        state === 'COMPLETED' ? 'bg-success-green' : 'bg-inactive-dark'
      } px-2 py-1 rounded-md text-white`}
    >
      {state}
    </span>
  );
}
