import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import { useLoadingTaskDetails, useMMLoadingForceComplete } from 'apiHooks/wmsHooks';
import React from 'react';
import { Panel, PanelGroup } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime } from 'utils/utils';
import { ForceComplete } from 'wms/molecules/ForceComplete';
import { CardWrapper } from 'wms/molecules/SessionTasks';

export default function LoadingDetails(props) {
  const { taskId } = props;
  const branchId = useBranchId();
  const { data: task, isFetching } = useLoadingTaskDetails({ branchId, taskId });
  // const isTaskCompleted = task?.state === 'COMPLETED';
  const isTaskCompleted = true;

  const getPalletsByType = (type) => {
    return (
      task?.chus
        ?.filter((c) => c.type === type)
        ?.reduce((a, c) => {
          const key = c.pallet?.code || 'SBL';
          return { ...a, [key]: [...(a[key] || []), c] };
        }, {}) || {}
    );
  };
  const cfcPallets = getPalletsByType('CFC');
  const gtlPallets = getPalletsByType('GTL');
  const ptlPallets = getPalletsByType('PTL');
  // const [loadAll] = useMMLoadingLoadAll({ branchId });
  const [forceComplete] = useMMLoadingForceComplete({ branchId });

  // const onLoadAll = () => loadAll({ taskId });
  const onForceComplete = () => forceComplete({ taskId });

  return (
    <div>
      {isFetching ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex w-full h-full p-5">
        <div className="flex-1">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            Hu Task Details
          </p>
          <div>
            {task && (
              <div className="mx-5 mt-10">
                <p className="text-base md:text-base font-bold md:font-semibold">
                  LM Trip : {task.attrs?.lmTripCode}
                </p>
                <p className="text-base md:text-base font-bold md:font-semibold">
                  MM Trip : {task.attrs?.mmTripCode}
                </p>
                <p className="text md:text-xs font-medium md:font-medium mt-2">
                  {formatDateTime(new Date(task.createdAt))}
                </p>
                <p className="text md:text-xs font-medium md:font-medium mt-2">{task.code}</p>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {!task?.chus?.length && (
              <div className="mx-5 my-10">
                <p className="text-medium">No data found</p>
              </div>
            )}
            <PalletCard label="Full Cases" data={cfcPallets} />
            <PalletCard label="PTL" data={gtlPallets} />
            <PalletCard label="SBL" data={ptlPallets} expanded={true} subCols={2} />
          </div>
        </div>
      </div>
      <ForceComplete {...{ isTaskCompleted, onForceComplete }} />
    </div>
  );
}

export function PalletCard({ label, data, expanded = false, subCols = 1 }) {
  const entries = Object.entries(data);
  if (entries?.length === 0) return null;

  return (
    <div>
      <p className="mx-5 mt-5 text-base font-bold">{label}</p>
      <div className={`grid grid-cols-1 lg:grid-cols-${subCols === 1 ? 2 : 1} px-3`}>
        {entries.map(([palletCode, chus]) => (
          <PanelGroup
            key={palletCode}
            accordion
            bordered
            defaultExpanded={expanded}
            classPrefix="bg-white m-2 md:rounded-md lg:rounded-lg"
          >
            <Panel
              header={`${palletCode} (${chus.filter((c) => !!c.loadedAt).length}/${chus.length})`}
              shaded
              defaultExpanded={expanded}
            >
              <ChuItems chus={chus} cols={subCols} />
            </Panel>
          </PanelGroup>
        ))}
      </div>
    </div>
  );
}

export function ChuItems({ chus, cols = 1 }) {
  return (
    <div className={`grid grid-cols-1 gap-2 md:grid-cols-${cols}`}>
      {chus
        .sort((a, b) => new Date(a.loadedAt) - new Date(b.loadedAt))
        .map((chu) => (
          <ChuItem key={chu.id} chu={{ ...chu, state: chu.loadedAt ? 'COMPLETED' : 'PENDING' }} />
        ))}
    </div>
  );
}

export function ChuItem({ chu }) {
  return (
    <CardWrapper state={chu.state} className="p-1">
      <div className="flex flex-1">
        <div className="flex-col flex-grow">
          <p className="text-xm md:text-xm font-semibold md:font-semibold m-1">{chu?.code}</p>
          <div className="flex flex-col">
            <p className="text-xs md:text-xs font-medium md:font-medium">
              {chu?.loadedAt ? formatDateTime(new Date(chu.loadedAt)) : '--'}
            </p>
          </div>
        </div>
        <div>
          {chu.loadedAt ? <CheckIcon size={12} /> : <CrossIcon size={12} color="secondary" />}
        </div>
      </div>
    </CardWrapper>
  );
}
