import React from 'react';

export const gridCols = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
};

const columns = {
  1: 'sm:w-1/1 md:w-1/1',
  2: 'sm:w-1/1 md:w-1/2',
  3: 'sm:w-1/2 md:w-1/3',
  4: 'sm:w-1/2 md:w-1/4',
  5: 'sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5',
  6: 'sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6',
};
export const colSpan = {
  one: 'sm:w-1/12 md:w-1/12 lg:w-1/12 xl:w-1/12',
  two: 'sm:w-3/12 md:w-2/12 lg:w-2/12 xl:w-2/12',
  three: 'sm:w-9/12 md:w-3/12 lg:w-3/12 xl:w-3/12',
  four: 'sm:w-5/12 md:w-4/12 lg:w-4/12 xl:w-4/12',
  full: 'sm:w-12/12 md:w-12/12 lg:w-12/12 xl:w-12/12',
};
export const twStyles = {
  container: 'container font-bold mx-auto sm:w-1/1 lg:w-4/6',
};
const getElements = (childs, cols) => {
  return childs.map(
    (child, index) =>
      child && (
        <div key={index} className={`w-full my-2 px-4 ${columns[cols]} ${child.props?.twclass}`}>
          {child}
        </div>
      )
  );
};

export function ContainerBox({ children, cols = 3 }) {
  return (
    <div
      className={`${twStyles.container} flex flex-wrap justify-between text-right py-10 mb-10 bg-white border-1 rounded-lg`}
    >
      {getElements(children, cols)}
    </div>
  );
}
export function SubContainerBox({ children, cols = 3 }) {
  return (
    <div className="mx-auto w-full flex flex-wrap items-center justify-between">
      {getElements(children, cols)}
    </div>
  );
}
export default ContainerBox;
