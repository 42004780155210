import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Slide from '@material-ui/core/Slide';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from '@material-ui/lab/Pagination';
import React, { useMemo, useState } from 'react';
import { formatDateTime } from 'utils/utils';
import WmsFilters from '../../WmsFilters';

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SortSessionList(props) {
  const { sessionList, onFiltersChange, onFilterCancelled, onDeleteSession } = props;
  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * 12, [activePage]);

  const handlePageChange = (e, value) => {
    setActivePage(value);
  };

  return (
    <div className="flex flex-col w-full h-full">
      {/* Header Section containing Filters */}
      <div className="flex flex-row m-5 items-center">
        <div className="flex-1">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            Session List
          </p>
        </div>
        <div className="flex-2">
          <WmsFilters
            onFiltersChange={(selectedDates) => {
              onFiltersChange(selectedDates);
            }}
            onFilterCancelled={onFilterCancelled}
          />
        </div>

        {/* <div className="flex flex-row-reverse flex-3 justify-center items-center space-x-2">
          <Select
            className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
            options={filterOptions}
            onChange={(value) => onFilterSelected(value)}
          />
          <input
            className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
            placeholder="Search Sessions"
          />
        </div>
       */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4">
        {sessionList?.slice(offset, offset + 12).map((item) => {
          return (
            <SessionItem
              key={item?.id}
              session={item}
              onDeleteClicked={(id) => onDeleteSession(id)}
            />
          );
        })}
      </div>

      <div className="mt-5 m-auto">
        {sessionList?.length / 12 > 1 && (
          <Pagination onChange={handlePageChange} count={Math.ceil(sessionList?.length / 12)} />
        )}
      </div>
    </div>
  );
}

export function SessionItem(props) {
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const { session, onDeleteClicked } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <div className="flex flex-row">
        <div className="flex-2 flex-col flex-grow">
          <p className="text-base md:text-base font-bold md:font-bold">{session?.code}</p>
          <div className="flex flex-col">
            <p className="text-xs md:text-xs font-medium md:font-medium">
              {formatDateTime(new Date(session?.createdAt))}
            </p>
            <p className="text-xs md:text-xs font-medium md:font-medium">{session?.state}</p>
          </div>
        </div>
        <div className="flex-1 flex-grow">
          <div className="float-right">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              value={session}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem value={`append ${session?.id}`} onClick={() => setAnchorEl(null)}>
                Append
              </MenuItem>
              <MenuItem
                value={`delete ${session?.id}`}
                onClick={() => {
                  setDeleteDialogVisibility(true);
                  setAnchorEl(null);
                }}
              >
                Delete
              </MenuItem>
              <a
                className="no-underline"
                href={`/wms/outbound/sorting/tasks/${session.code}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setAnchorEl(null)}
              >
                <MenuItem>Complete</MenuItem>
              </a>
            </Menu>
          </div>
        </div>
      </div>
      <Dialog
        open={deleteDialogVisibility}
        TransitionComponent={transition}
        keepMounted
        onClose={() => setDeleteDialogVisibility(false)}
        aria-labelledby="delete-dialog-slide-title"
        aria-describedby="delete-dialog-slide-description"
      >
        <DialogTitle id="delete-dialog-slide-title">{'Please confirm your action'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-slide-description">
            Your are attempting to delete the sorting unit. Are you sure about your choice ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogVisibility(false)} color="primary">
            No
          </Button>
          <Button
            disabled={session?.state === 'PENDING' ? false : true}
            onClick={() => {
              onDeleteClicked(session?.id);
              setDeleteDialogVisibility(false);
            }}
            color="primary"
          >
            Yes, Delete it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
