import { useEWayBillGenerate } from 'apiHooks/wmsHooks';
import { useMemo, useState } from 'react';
import { useBranchId } from 'utils/customHooks';

export const useEWayBillHook = ({ tripList }) => {
  const config = [
    { id: 'vehicleNo', label: 'Vehicle No.' },
    { id: 'vehicleType', label: 'Vehicle Type' },
    { id: 'transporterName', label: 'Transporter Name' },
    { id: 'transporterId', label: 'Transporter Id' },
    { id: 'transDocNo', label: 'Transporter Doc No.' },
  ];
  const initState = config.reduce((a, c) => ({ ...a, [c.id]: '' }), {});
  const [state, setState] = useState(initState);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const branchId = useBranchId();
  const [generateBill] = useEWayBillGenerate({ branchId });

  const tripOptions = useMemo(
    () =>
      tripList.map((item) => {
        const date = item.mmTrip.substring(0, 10);
        return {
          value: item.id,
          label: `MM: ${item.mmTrip.substring(11)}, LM: ${item.lmTrip.substring(11)} (${date})`,
        };
      }),
    [tripList]
  );

  // const payload = {
  //   mmLoadingTaskIds: ['3fa85f64-5717-4562-b3fc-2c963f66afa6'],
  //   vehicleNo: 'string',
  //   vehicleType: 'string',
  //   transporterName: 'string',
  //   transporterId: 'string',
  //   transDocNo: 'string',
  //   transDocDate: 'string',
  // };
  const downloadBill = (type) => {
    const data = { mmLoadingTaskIds: selectedTrips.map((t) => t.value), ...state };
    generateBill({ type, data });
  };
  const onChange = (id, val) => setState((p) => ({ ...p, [id]: val }));

  return {
    tripOptions,
    selectedTrips,
    config,
    initState,
    state,
    setSelectedTrips,
    onChange,
    setState,
    downloadBill,
  };
};
