import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Upgrade from '@material-ui/icons/SystemUpdateAlt';
import { useWMSBinsDownload } from 'apiHooks/wmsHooks';
import React from 'react';
import { useBranchId } from 'utils/customHooks';

export const useBinUploads = () => {
  const defaultButtons = [
    {
      type: '/sample',
      name: 'Sample',
      isPost: false,
      className: 'text-blue',
      icon: <CloudDownloadIcon />,
    },
    {
      type: '/download',
      name: 'Download',
      isPost: false,
      className: 'text-blue_secondary',
      icon: <CloudDownloadIcon />,
    },
    {
      type: '/upload',
      name: 'Upload',
      isPost: true,
      className: 'text-light_blue',
      icon: <CloudUploadIcon />,
    },
  ];
  const updateAndDeleteButtons = [
    {
      type: '/update',
      name: 'Update',
      isPost: true,
      className: 'text-orange',
      icon: <Upgrade />,
    },
    {
      type: '/delete',
      name: 'Delete',
      isPost: true,
      className: 'text-danger',
      icon: <DeleteForeverIcon />,
    },
  ];
  const uploadList = [
    {
      name: 'Storage Structure',
      url: 'wms/storage/structure',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'Storage-SKUs Relationships',
      url: 'wms/storage/search-table',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'Fixed SKUs',
      url: 'wms/storage/fixed-sku',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'SKUs Best-fit Counts',
      url: 'wms/storage/best-fit',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'Dock-Doors Mapping',
      url: 'wms/storage/bin-dockdoor',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'Outbound Priority',
      url: 'ob_priority',
      buttons: defaultButtons.map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/template' : btn.name === 'Upload' ? '' : '/download',
        };
      }),
    },
    {
      name: 'Storage Position',
      url: 'wms/storage/position',
      buttons: [...defaultButtons.slice(1)],
    },
    {
      name: 'Auto-Replen SKU Capacity',
      url: 'wms/auto-replen/sku-capacity',
      buttons: defaultButtons,
    },
    {
      name: "Append/Reset Inventory",
      url: 'wms/storage/inventory/xlsx',
      buttons: defaultButtons.filter((button, index) => index !== 1).map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/sample' : btn.name === 'Upload' ? '' : '/download',
        };
      }),
    },
    {
      name: 'Add Salesman Group',
      url: 'wms-salesman-group',
      buttons: defaultButtons.map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/template' : btn.name === 'Upload' ? '' : '/download',
        };
      }),
    },
    {
      name: "SKU Classification",
      url: 'wms/sku/classification',
      buttons: defaultButtons.filter((button, index) => index !== 1).map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/sample/xlsx' : btn.name === 'Upload' ? '/xlsx' : '/download',
        };
      }),
    },
    {
      name: "Add New Crates & Pallets",
      url: 'wms/handling_unit',
      buttons: defaultButtons.filter((button, index) => index !== 1).map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/xlsx/sample' : btn.name === 'Upload' ? '/xlsx' : '/download',
        };
      }),
    },
    {
      name: "HU-Bin Mapping",
      url: 'wms/handling_unit/store',
      buttons: defaultButtons.slice(2).map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Upload' ? '/xlsx' : btn.name === 'Sample' ? '' : '/download',
        };
      }),
    },
    {
      name: "Sampling Plan Upload",
      url: 'wms/inbound/sampling-plan',
      buttons: defaultButtons.slice(2).map((btn) => {
        return {
          ...btn,
          type: btn.name === 'Sample' ? '/xlsx' : btn.name === 'Upload' ? '/upload' : '/download',
        };
      }),
    },
    {
      name: 'Parking Structure',
      url: 'parking',
      buttons: [...defaultButtons.slice(1)],
    },
    // #TODO Remove all item from here
    {
      name: 'Add Storage Areas',
      url: 'bin/storage_area',
      disabled: true,
      buttons: [...defaultButtons, ...updateAndDeleteButtons],
    },
    {
      name: 'Add Storage Zones',
      url: 'bin/storage_zone',
      disabled: true,
      buttons: [...defaultButtons, ...updateAndDeleteButtons],
    },
    {
      name: 'Add Storage Bin types',
      url: 'bin/storage_type',
      disabled: true,
      buttons: [...defaultButtons, ...updateAndDeleteButtons],
    },
    {
      name: 'All Bins',
      url: 'bin',
      disabled: true,
      buttons: [...defaultButtons, ...updateAndDeleteButtons],
    },
    {
      name: 'Fixed Bin SKUs',
      url: 'bin/fixed_sku',
      disabled: true,
      buttons: [...defaultButtons],
    },
    {
      name: 'Dock Doors',
      url: 'bin/dockdoor',
      disabled: true,
      buttons: [...defaultButtons],
    },
  ];

  const branchId = useBranchId();
  const [download, { status: downloadStatus }] = useWMSBinsDownload({ branchId });
  const isFetching = downloadStatus === 'loading';

  return { uploadList, isFetching, download };
};
