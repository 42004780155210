import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import {
  usePtlSortingDashboard,
  useSortingSessionData,
  useLiSortSessionData,
  usePTLSessionData,
  useOverallSortingSessionData,
} from 'apiHooks/wmsHooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from './../../../controlTower/components/Select';
import { Progress, Toggle } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { delay, format1, formatDayTime, formatTime, getDateRange, max } from 'utils/utils';
import WmsFilters from 'wms/WmsFilters';
const { Line } = Progress;

const initialDates = getDateRange(new Date(), 7);
const colors = { SUCCESS: '#52c41a', ERROR: '#ffa500', primary: '#31405C' };

const usePtlDashboard = () => {
  const [dateRange, setDateRange] = useState(initialDates);
  const [wave, setWave] = useState({});
  const [wave2, setWave2] = useState({});
  const [selectedSession, setSelectedSession] = useState({});
  const branchId = useBranchId();
  const dashboardUnitOptions = ['LINE', 'QTY', 'SKU'].map((x) => ({ label: x, value: x }));
  const [liSortDashboardUnit, setLiSortDashboardUnit] = useState('');
  const [sblDashboardUnit, setSblDashboardUnit] = useState('');
  const [ptlDashboardUnit, setPtlDashboardUnit] = useState('');
  const [selectedLiSortDashboardUnit, setSelectedLiSortDashboardUnit] = useState('');
  const [selectedSblDashboardUnit, setSelectedSblDashboardUnit] = useState('');
  const [selectedPtlDashboardUnit, setSelectedPtlDashboardUnit] = useState('');
  const [autoRefresh, setAutoRefresh] = useState(true);
  const { data: sessions, isFetching: sessionFetching } = useSortingSessionData({
    branchId,
    query: { createdAtWindowStart: dateRange[0], createdAtWindowEnd: dateRange[1] },
  });
  const {
    data: sessionData2,
    isFetching: dashboardFetching2,
    refetch: dashboardRefetch2,
  } = useOverallSortingSessionData({
    branchId,
    sessionId: selectedSession.value,
    autoRefresh,
    onError: () => dashboardRefetch2(),
  });

  const {
    data: sessionData3,
    isFetching: dashboardFetching3,
    refetch: dashboardRefetch3,
  } = useLiSortSessionData({
    branchId,
    sessionId: selectedSession.value,
    autoRefresh,
    onError: () => dashboardRefetch3(),
    onSuccess: (data) => {
      setLiSortDashboardUnit(data?.sortingDashboardUnit);
    },
  });

  const {
    data: sessionData4,
    isFetching: dashboardFetching4,
    refetch: dashboardRefetch4,
  } = usePTLSessionData({
    branchId,
    sessionId: selectedSession.value,
    autoRefresh,
    onError: () => dashboardRefetch4(),
    onSuccess: (data) => setPtlDashboardUnit(data?.sortingDashboardUnit),
  });

  const {
    data: sessionData,
    isFetching: dashboardFetching,
    refetch: dashboardRefetch,
  } = usePtlSortingDashboard({
    branchId,
    sessionId: selectedSession.value,
    autoRefresh,
    onError: () => dashboardRefetch(),
    onSuccess: (data) => {
      setSblDashboardUnit(data?.sortingDashboardUnit);
    },
  });

  const activeLiSortDashboardUnit = useMemo(
    () => selectedLiSortDashboardUnit || liSortDashboardUnit,
    [selectedLiSortDashboardUnit, liSortDashboardUnit]
  );
  const activeSblDashboardUnit = useMemo(() => selectedSblDashboardUnit || sblDashboardUnit, [
    selectedSblDashboardUnit,
    sblDashboardUnit,
  ]);
  const activePtlDashboardUnit = useMemo(() => selectedPtlDashboardUnit || ptlDashboardUnit, [
    selectedPtlDashboardUnit,
    ptlDashboardUnit,
  ]);

  const isFetching =
    sessionFetching ||
    dashboardFetching2 ||
    dashboardFetching3 ||
    dashboardFetching4 ||
    dashboardFetching;
  const sessionOptions = useMemo(
    () => sessions?.map((x) => ({ ...x, value: x.id, label: x.code })),
    [sessions]
  );
  const waveOptions = useMemo(
    () =>
      sessionData?.waves
        .sort((a, b) => a.index - b.index)
        .map((x) => ({ ...x, label: x.index + 1, value: x.index })),
    [sessionData]
  );
  const activeWave = useMemo(() => waveOptions?.find((x) => x.state !== 'COMPLETED'), [
    waveOptions,
  ]);
  const waveOptions2 = useMemo(
    () =>
      sessionData3?.waves
        .sort((a, b) => a.index - b.index)
        .map((x) => ({ ...x, label: x.index + 1, value: x.index })),
    [sessionData3]
  );
  const activeWave2 = useMemo(() => waveOptions2?.find((x) => x.state !== 'COMPLETED'), [
    waveOptions2,
  ]);

  const disabledAutoRefresh = activeWave?.index !== selectedWave?.value;
  const selectedWave = waveOptions?.find((w) => w.index === wave.index) || {};
  const selectedWave2 = waveOptions2?.find((w) => w.index === wave2.index) || {};

  const onDateFilterChanged = (dates) => {
    setDateRange(dates.length ? dates : initialDates);
  };
  const onSelectSession = (e) => {
    setSelectedSession(e);
    setSelectedLiSortDashboardUnit();
    setSelectedPtlDashboardUnit();
    setSelectedSblDashboardUnit();
  };

  const getDayTime = (date) => (date ? formatDayTime(new Date(date)) : '');

  const customStyles = {
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#31405C' }),
  };

  const waveMetric = useMemo(
    () => [
      {
        label: 'Unit',
        key: 1,
        value: (
          <Select
            options={dashboardUnitOptions}
            onChange={(value) => setSelectedSblDashboardUnit(value?.value)}
            value={{
              label: activeSblDashboardUnit,
              value: activeSblDashboardUnit,
            }}
            placeholder=""
            className={'w-28 ml-3'}
            styles={customStyles}
          />
        ),
      },
      { value: getDayTime(selectedWave.startTime), label: 'Start Time', key: 2 },

      {
        value: getDayTime(
          activeSblDashboardUnit === 'LINE'
            ? selectedWave?.line?.plannedCompletionTime
            : activeSblDashboardUnit === 'QTY'
            ? selectedWave?.qty?.plannedCompletionTime
            : selectedWave?.sku?.plannedCompletionTime
        ),
        label: 'Planned Completion Time',
        key: 3,
      },
      { value: getDayTime(sessionData?.now), label: 'Current Time', key: 4 },

      {
        value:
          activeSblDashboardUnit === 'LINE'
            ? selectedWave?.line?.percentageTimeElapsed
            : activeSblDashboardUnit === 'QTY'
            ? selectedWave?.qty?.percentageTimeElapsed
            : selectedWave?.sku?.percentageTimeElapsed,
        label: '%Time Elapsed',
        key: 5,
      },
      {
        value: getDayTime(
          activeSblDashboardUnit === 'LINE'
            ? selectedWave?.line?.expectedCompletionTime
            : activeSblDashboardUnit === 'QTY'
            ? selectedWave?.qty?.expectedCompletionTime
            : selectedWave?.sku?.expectedCompletionTime
        ),
        label: `${selectedWave.value >= activeWave?.index ? 'Expected' : 'Actual'} Completion Time`,
        key: 6,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeWave, selectedWave, sessionData, waveOptions, activeSblDashboardUnit]
  );

  const waveMetric2 = useMemo(
    () => [
      { value: sessionData2?.invoices?.total, label: 'Total Invoices', key: 1 },
      {
        value: sessionData2?.invoices?.completed,
        label: 'Invoices Completed',
        key: 2,
      },
      { value: sessionData2?.invoices?.pending, label: 'Invoices Pending', key: 3 },
      { value: sessionData2?.invoices?.partial, label: 'Partially Pending Invoices', key: 4 },
      { value: sessionData2?.lines?.total, label: 'Total Lines', key: 5 },
      { value: sessionData2?.lines?.completed, label: 'Lines Completed', key: 6 },
      { value: sessionData2?.lines?.pending, label: 'Lines Pending', key: 7 },
      { value: sessionData2?.lines?.partial, label: 'Partially Pending Lines', key: 8 },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionData2]
  );

  const sessionState2 = useMemo(() => sessionData2?.sessionState, [sessionData2]);

  const waveMetric3 = useMemo(
    () => [
      {
        label: 'Unit',
        key: 1,
        value: (
          <Select
            options={dashboardUnitOptions.slice(1)}
            onChange={(value) => setSelectedLiSortDashboardUnit(value?.value)}
            value={{
              label: activeLiSortDashboardUnit,
              value: activeLiSortDashboardUnit,
            }}
            placeholder=""
            className={'w-28 ml-3'}
            styles={customStyles}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeWave2, selectedWave2, sessionData3, waveOptions2, activeLiSortDashboardUnit]
  );

  const waveMetric4 = useMemo(
    () => [
      {
        label: 'Unit',
        key: 1,
        value: (
          <Select
            options={dashboardUnitOptions}
            onChange={(value) => setSelectedPtlDashboardUnit(value?.value)}
            value={{
              label: activePtlDashboardUnit,
              value: activePtlDashboardUnit,
            }}
            placeholder=""
            className={'w-28 ml-3'}
            styles={customStyles}
          />
        ),
      },
      {
        value: sessionData4?.invoices?.total,
        label: 'Total Invoices',
        key: 2,
      },
      {
        value: sessionData4?.invoices?.completed,
        label: 'Invoices Completed',
        key: 3,
      },
      {
        value: sessionData4?.invoices?.pending,
        label: 'Invoices Pending',
        key: 4,
      },
      { value: sessionData4?.chus, label: 'Total HUs', key: 5 },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionData4, activePtlDashboardUnit]
  );

  const stations3 = useMemo(
    () =>
      activePtlDashboardUnit === 'LINE'
        ? sessionData4?.stations?.map((x) => ({
            ...x.line,
            code: x.stationCode,
            state: x.state,
          }))
        : activePtlDashboardUnit === 'QTY'
        ? sessionData4?.stations?.map((x) => ({
            ...x.qty,
            code: x.stationCode,
            state: x.state,
          }))
        : sessionData4?.stations?.map((x) => ({
            ...x.sku,
            code: x.stationCode,
            state: x.state,
          })),
    [sessionData4, activePtlDashboardUnit]
  );

  const getPlanAdherence = (x) => {
    const delay = new Date(x.expectedCompletionTime) - new Date(x.plannedCompletionTime);
    return Math.ceil(delay / 1000 / 60);
  };

  const stations = selectedWave
    ? selectedWave.stations?.map((x) => ({
        ...x,
        code: x.stationCode,
        state: x.state,
        taskCompleted:
          activeSblDashboardUnit === 'LINE'
            ? x?.line?.progress
            : activeSblDashboardUnit === 'QTY'
            ? x?.qty?.progress
            : x?.sku?.progress,
        planAdherence: getPlanAdherence(
          activeSblDashboardUnit === 'LINE'
            ? x?.line
            : activeSblDashboardUnit === 'QTY'
            ? x?.qty
            : x?.sku
        ),
        expectedCompletionTime:
          activeSblDashboardUnit === 'LINE'
            ? x?.line?.expectedCompletionTime
            : activeSblDashboardUnit === 'QTY'
            ? x?.qty?.expectedCompletionTime
            : x?.sku?.expectedCompletionTime,
      }))
    : [];
  const stations2 = selectedWave2
    ? selectedWave2.tasks
        ?.filter((task) => task.type === 'PTL')
        .map((x) => ({
          ...x,
          code: x.ref,
          state: x.state,
        }))
    : [];
  const sessionState3 = selectedWave2?.state;
  const fullCaseArm = selectedWave2
    ? selectedWave2.tasks
        ?.filter((task) => task.type === 'TRIP')
        .reduce((accumulator, currentValue) => {
          accumulator.qty = {
            target: (accumulator.qty?.target || 0) + currentValue.qty.target,
            done: (accumulator.qty?.done || 0) + currentValue.qty.done,
          };
          accumulator.sku = {
            target: (accumulator.sku?.target || 0) + currentValue.sku.target,
            done: (accumulator.sku?.done || 0) + currentValue.sku.done,
          };
          return accumulator;
        }, {})
    : [];

  const maxAdherence = max(stations, (x) => Math.abs(x.planAdherence));

  // const maxTaskCompleted = max(stations, (x) => x.taskCompleted);

  const getPlanAdherencePercent = (percent) => {
    if (!maxAdherence) return Math.max(percent, 100);
    if (percent == 0) return 100;

    return format1((Math.abs(percent) * 100) / maxAdherence);
  };

  const getPlanAdherenceLabel = (s) => {
    const diff = s.planAdherence;

    if (diff === 0)
      return {
        label: 'On Time',
        color: colors.SUCCESS,
      };

    if (diff > 0) {
      return {
        label: `-${formatTime(diff * 60)}`,
        color: colors.ERROR,
      };
    }

    return {
      label: `+${formatTime(-1 * diff * 60)}`,
      color: colors.SUCCESS,
    };
  };

  const sessionState4 = useMemo(() => sessionData4?.sessionState, [sessionData4]);

  useEffect(() => {
    if ((activeWave || waveOptions?.length) && !Object.keys(wave).length)
      setWave(activeWave || waveOptions[0]);
  }, [activeWave, wave, waveOptions]);

  useEffect(() => {
    if ((activeWave2 || waveOptions2?.length) && !Object.keys(wave2).length)
      setWave2(activeWave2 || waveOptions2[0]);
  }, [activeWave2, wave2, waveOptions2]);

  useEffect(() => {
    if (autoRefresh && sessionData) {
      delay(30 * 1000).then(() => dashboardRefetch());
    }

    if (autoRefresh && sessionData2) {
      delay(30 * 1000).then(() => dashboardRefetch2());
    }

    if (autoRefresh && sessionData3) {
      delay(30 * 1000).then(() => dashboardRefetch3());
    }

    if (autoRefresh && sessionData4) {
      delay(30 * 1000).then(() => dashboardRefetch4());
    }

    if (sessionOptions?.length && !Object.keys(selectedSession).length) {
      setSelectedSession(sessionOptions[0]);
    }
    // if (activeWave && selectedWave) {
    //   autoRefresh.current = activeWave.value === selectedWave.value;
    // }
  }, [
    autoRefresh,
    dashboardRefetch,
    dashboardRefetch2,
    dashboardRefetch3,
    dashboardRefetch4,
    selectedSession,
    sessionData,
    sessionData2,
    sessionData3,
    sessionData4,
    sessionOptions,
  ]);

  return {
    isFetching,
    autoRefresh,
    session: selectedSession,
    stations,
    stations2,
    stations3,
    fullCaseArm,
    sessionOptions,
    disabledAutoRefresh,
    waveMetric,
    waveMetric2,
    waveMetric3,
    waveMetric4,
    waveOptions,
    waveOptions2,
    sessionState2,
    sessionState3,
    sessionState4,
    getDayTime,
    onDateFilterChanged,
    onSelectSession,
    setAutoRefresh,
    getPlanAdherencePercent,
    getPlanAdherenceLabel,
    activeLiSortDashboardUnit,
    activeSblDashboardUnit,
    activePtlDashboardUnit,
  };
};
export const SortingDashboard = () => {
  const {
    isFetching,
    autoRefresh,
    session,
    waveMetric,
    waveMetric2,
    waveMetric3,
    waveMetric4,
    waveOptions,
    waveOptions2,
    stations,
    stations2,
    stations3,
    sessionState2,
    sessionState3,
    sessionState4,
    fullCaseArm,
    sessionOptions,
    getDayTime,
    onDateFilterChanged,
    onSelectSession,
    setAutoRefresh,
    getPlanAdherencePercent,
    getPlanAdherenceLabel,
    activeLiSortDashboardUnit,
    activeSblDashboardUnit,
    activePtlDashboardUnit,
  } = usePtlDashboard();

  const fRef = useRef(null);

  return (
    <div className="flex flex-col flex-1">
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}
      <div className="flex flex-1 flex-col pt-5 mx-1 md:mx-8 lg:mx-14">
        <div className="flex items-center justify-between">
          <WmsFilters
            show
            onFiltersChange={onDateFilterChanged}
            onFilterCancelled={() => onDateFilterChanged([])}
          />
          <Select
            options={sessionOptions}
            onChange={onSelectSession}
            value={session}
            placeholder="Select Sorting Session"
            className={'w-60 mr-3'}
            isSearchable={true}
          />
        </div>

        <div ref={fRef} className="flex flex-col">
          <div className="mt-4 mb-4 self-end flex">
            <Labeled
              label={'Auto Refresh'}
              value={
                <Toggle
                  // disabled={disabledAutoRefresh}
                  checked={autoRefresh}
                  onChange={(checked) => setAutoRefresh(checked)}
                  className="ml-2"
                />
              }
            />
          </div>
          <div>
            <div className="py-5 border-2 border-black rounded-lg">
              <div className="flex justify-between">
                <div className=" text-primary text-xl font-bold w-30 pl-3 ">OVERALL PROGRESS</div>
                <div className="float-right -mt-5">
                  <IconButton onClick={() => fRef.current.requestFullscreen()} edge="start">
                    <FullscreenIcon fontSize="large" />
                  </IconButton>
                </div>
              </div>
              <div className="grid flex-1 grid-cols-2">
                <div className="grid grid-cols-2 gap-1 p-1 pl-5">
                  {waveMetric2?.map((x) => x.key <= 4 && <Labeled key={x.key} {...x} />)}
                </div>
                <div className="grid grid-cols-2 gap-1 p-1">
                  {waveMetric2?.map((x) => x.key >= 5 && <Labeled key={x.key} {...x} />)}
                </div>
              </div>
              <div className="mt-2 px-5">
                <div className="flex flex-row items-center">
                  <div className="grid flex-1 grid-cols-2 gap-2">
                    <ProgressBar
                      percent={format1((waveMetric2[1].value / waveMetric2[0].value) * 100 || 0)}
                      label={`${format1(
                        (waveMetric2[1].value / waveMetric2[0].value) * 100 || 0
                      )}%`}
                      color={sessionState2 === 'COMPLETED' ? colors.SUCCESS : ''}
                    />
                    <ProgressBar
                      percent={format1((waveMetric2[5].value / waveMetric2[4].value) * 100 || 0)}
                      label={`${format1(
                        (waveMetric2[5].value / waveMetric2[4].value) * 100 || 0
                      )}%`}
                      color={sessionState2 === 'COMPLETED' ? colors.SUCCESS : ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!!waveOptions2?.length && (
              <div className="py-5 border-2 border-black rounded-lg mt-4">
                <div className="flex justify-between">
                  <div className="flex space-x-10 items-center">
                    <div className=" text-primary text-xl font-bold w-30 pl-3 ">LINEAR SORTER</div>
                  </div>
                  <div className="float-right flex items-center justify-between space-x-5 -mt-5">
                    {waveMetric3?.map((x) => (
                      <Labeled key={x.key} {...x} />
                    ))}
                    <IconButton onClick={() => fRef.current.requestFullscreen()} edge="start">
                      <FullscreenIcon fontSize="large" />
                    </IconButton>
                  </div>
                </div>
                <div className="mt-2 px-5">
                  <div className="text-base text-primary font-bold">SBL Arms</div>
                  <div className="grid grid-cols-2 gap-1 p-1">
                    {stations2?.map((s) => {
                      return (
                        <div key={s.code} className="flex flex-row items-center my-auto">
                          <span className="text-base text-primary font-bold my-auto">{s.code}</span>
                          <div className="w-4/5">
                            <ProgressBar
                              percent={format1(
                                (activeSblDashboardUnit === 'QTY'
                                  ? s?.qty?.done / s?.qty?.target
                                  : s?.sku?.done / s?.sku?.target) * 100 || 0
                              )}
                              label={`${format1(
                                (activeSblDashboardUnit === 'QTY'
                                  ? s?.qty?.done / s?.qty?.target
                                  : s?.sku?.done / s?.sku?.target) * 100 || 0
                              )}%`}
                              color={sessionState3 === 'COMPLETED' ? colors.SUCCESS : ''}
                            />
                          </div>
                          <span className="text-base text-primary font-bold my-auto">
                            {activeLiSortDashboardUnit === 'QTY' ? s?.qty?.done : s?.sku?.done} /{' '}
                            {activeLiSortDashboardUnit === 'QTY' ? s?.qty?.target : s?.sku?.target}{' '}
                            {activeLiSortDashboardUnit === 'QTY' ? 'EAs' : 'Skus'}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-2 px-5">
                  <div className="text-base text-primary font-bold ml-1">Full Cases Arms</div>
                  {fullCaseArm !== null &&
                    typeof fullCaseArm === 'object' &&
                    Object.keys(fullCaseArm).length > 0 && (
                      <div className="grid flex-1 grid-cols-2 gap-1 p-1">
                        <div className="flex flex-row items-center my-auto">
                          <div className="w-4/5 ml-7">
                            <ProgressBar
                              percent={format1(
                                (activeLiSortDashboardUnit === 'QTY'
                                  ? fullCaseArm?.qty?.done / fullCaseArm?.qty?.target
                                  : fullCaseArm?.sku?.done / fullCaseArm?.sku?.target) * 100 || 0
                              )}
                              label={`${format1(
                                (activeLiSortDashboardUnit === 'QTY'
                                  ? fullCaseArm?.qty?.done / fullCaseArm?.qty?.target
                                  : fullCaseArm?.sku?.done / fullCaseArm?.sku?.target) * 100 || 0
                              )}%`}
                              color={sessionState3 === 'COMPLETED' ? colors.SUCCESS : ''}
                            />
                          </div>
                          <span className="text-base text-primary font-bold my-auto">
                            {activeLiSortDashboardUnit === 'QTY'
                              ? fullCaseArm?.qty?.done
                              : fullCaseArm?.sku?.done}{' '}
                            /{' '}
                            {activeLiSortDashboardUnit === 'QTY'
                              ? fullCaseArm?.qty?.target
                              : fullCaseArm?.sku?.target}{' '}
                            {activeLiSortDashboardUnit === 'QTY' ? 'Full Case' : 'Skus'}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            {!!waveOptions?.length && (
              <div className="py-5 border-2 border-black rounded-lg mt-4">
                <div className="flex justify-between">
                  <div className="flex space-x-10 items-center">
                    <div className=" text-primary text-xl font-bold w-30 pl-3 ">SBL</div>
                  </div>
                  <div className="float-right flex items-center justify-between space-x-5 -mt-5">
                    {waveMetric?.map((x) => x.key === 1 && <Labeled key={x.key} {...x} />)}
                    <IconButton onClick={() => fRef.current.requestFullscreen()} edge="start">
                      <FullscreenIcon fontSize="large" />
                    </IconButton>
                  </div>
                </div>
                <div className="flex justify-between p-3">
                  {waveMetric?.map((x) => x.key >= 2 && <Labeled key={x.key} {...x} />)}
                </div>
                <div className="mt-2 px-5">
                  <div className="flex flex-row items-center">
                    <div className="text-base text-primary font-bold w-30"></div>
                    <div className="grid flex-1 grid-cols-2 gap-2">
                      <div className="text-base text-primary font-bold ml-11">Task Completed</div>
                      <div className="text-base text-primary font-bold ml-2">Plan Adherence</div>
                    </div>
                    <div className="text-base text-primary font-bold"></div>
                  </div>
                  {stations?.map((s) => {
                    const adherence = getPlanAdherenceLabel(s);
                    return (
                      <div key={s.stationCode} className="flex flex-row items-center">
                        <div className="grid flex-1 grid-cols-2 gap-1 p-1">
                          <div className="flex">
                            <span className="text-base text-primary font-bold my-auto">
                              {s.stationCode}
                            </span>
                            <div className="w-4/5">
                              <ProgressBar
                                percent={
                                  activeSblDashboardUnit === 'LINE'
                                    ? format1(s?.line?.progress)
                                    : activeSblDashboardUnit === 'QTY'
                                    ? format1(s?.qty?.progress)
                                    : format1(s?.sku?.progress)
                                }
                                label={`${
                                  activeSblDashboardUnit === 'LINE'
                                    ? format1(s?.line?.progress)
                                    : activeSblDashboardUnit === 'QTY'
                                    ? format1(s?.qty?.progress)
                                    : format1(s?.sku?.progress)
                                }%`}
                                color={s.state === 'COMPLETED' ? colors.SUCCESS : ''}
                              />
                            </div>
                            <span className="text-base text-primary font-bold my-auto">
                              {activeSblDashboardUnit === 'LINE'
                                ? s?.line?.done
                                : activeSblDashboardUnit === 'QTY'
                                ? s?.qty?.done
                                : s?.sku?.done}{' '}
                              /{' '}
                              {activeSblDashboardUnit === 'LINE'
                                ? s?.line?.target
                                : activeSblDashboardUnit === 'QTY'
                                ? s?.qty?.target
                                : s?.sku?.target}{' '}
                              {activeSblDashboardUnit === 'LINE'
                                ? 'Lines'
                                : activeSblDashboardUnit === 'QTY'
                                ? 'EAs'
                                : 'Skus'}
                            </span>
                          </div>
                          <div className="flex">
                            <div className="w-5/6">
                              <ProgressBar
                                percent={getPlanAdherencePercent(s.planAdherence)}
                                {...adherence}
                              />
                            </div>
                            <span className="text-base text-primary font-bold my-auto">
                              {getDayTime(
                                activeSblDashboardUnit === 'LINE'
                                  ? s?.line?.expectedCompletionTime
                                  : activeSblDashboardUnit === 'QTY'
                                  ? s?.qty?.expectedCompletionTime
                                  : s?.sku?.expectedCompletionTime
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!!stations3?.length && (
              <div className="py-5 border-2 border-black rounded-lg mt-4">
                <div className="flex justify-between">
                  <div className="flex space-x-10 items-center">
                    <div className=" text-primary text-xl font-bold w-30 pl-3 ">PTL</div>
                  </div>
                  <div className="float-right flex items-center justify-between space-x-5 -mt-5">
                    {waveMetric4?.map((x) => x.key === 1 && <Labeled key={x.key} {...x} />)}
                    <IconButton onClick={() => fRef.current.requestFullscreen()} edge="start">
                      <FullscreenIcon fontSize="large" />
                    </IconButton>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-1 p-3 pt-1">
                  {waveMetric4?.map((x) => x.key >= 2 && <Labeled key={x.key} {...x} />)}
                </div>
                <div className="grid grid-cols-2 gap-1 p-1 px-5">
                  {stations3?.map((s) => {
                    return (
                      <div key={s.code} className="flex flex-row items-center my-auto">
                        <span className="text-base text-primary font-bold my-auto">{s.code}</span>
                        <div className="w-4/5">
                          <ProgressBar
                            percent={format1((s.done / s.target) * 100 || 0)}
                            label={`${format1((s.done / s.target) * 100 || 0)}%`}
                            color={sessionState4 === 'COMPLETED' ? colors.SUCCESS : ''}
                          />
                        </div>
                        <span className="text-base text-primary font-bold my-auto">
                          {s.done} / {s.target}{' '}
                          {activePtlDashboardUnit === 'LINE'
                            ? 'Lines'
                            : activePtlDashboardUnit === 'QTY'
                            ? 'EAs'
                            : 'Skus'}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ percent, label, color }) => {
  const absPercent = Math.abs(percent);
  return (
    <div className="relative items-center">
      <p
        className={`absolute inset-x-0 w-2/12 z-10 top-5 font-bold mx-auto ${
          absPercent > 50 ? 'text-white' : 'text-primary'
        }`}
      >
        {label}
      </p>
      <Line
        percent={absPercent}
        strokeColor={color}
        strokeWidth={40}
        status={'active'}
        showInfo={false}
      />
    </div>
  );
};

const Labeled = ({ label, value }) => {
  return (
    <div className="flex items-center text-primary text-lg font-bold mr-2">
      {label}: {value}
    </div>
  );
};
