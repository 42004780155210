import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import React, { useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Modal } from 'rsuite';
import { useBranchId, useSettings } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { getDateRange } from 'utils/utils';
import sortingReducer, { actions } from './sortingReducer';
import SortSessionDialogContent from './SortSessionDialogContent';
import OrderBasedSortCreate from './OrderBasedSortCreate';
import SortSessionList from './SortSessionList';

const initialDates = getDateRange(new Date(), 7);

export default function Sorting() {
  const branchId = useBranchId();
  const [startDate, setStartDate] = useState(initialDates[0]);
  const [endDate, setEndDate] = useState(initialDates[1]);
  const [sortSessionDialogVisibility, setSortSessionDialogVisibility] = useState(false);
  const [state, dispatch] = useReducer(sortingReducer, {});
  const [statistics, setStatistics] = useState({});
  const { wms } = useSettings();
  const isOrderBased = wms?.enableOrderBasedOutbound;
  const { refetch: refetchSortSession, isFetching: isFetchingSort } = useQuery(
    branchId && startDate && endDate && ['sortSessionList', { branchId, startDate, endDate }],
    () =>
      fetch({
        url: 'wms/sortation/session',
        method: 'GET',
        headers: {
          'x-wh-id': branchId,
        },
        params: {
          createdAtWindowStart: startDate,
          createdAtWindowEnd: endDate,
        },
      }),
    {
      onSuccess: (data) => {
        dispatch([actions.INIT, data]);
      },
    }
  );

  const [getTrips] = useMutation(
    ({ nodeId, startDate, endDate }) =>
      fetch({
        url: 'wms-sorting/trip',
        method: 'GET',
        params: {
          nodeId: nodeId,
          startDate: startDate,
          endDate: endDate,
        },
      }),
    {
      onSuccess: (data) => {
        dispatch([actions.UPDATE_TRIPS, data]);
      },
    }
  );

  const [getStatistics] = useMutation(
    ({ nodeId, tripIds }) =>
      fetch({
        url: 'wms-sorting/session/statistics',
        method: 'POST',
        params: {
          nodeId: nodeId,
        },
        data: {
          tripIds: tripIds,
        },
      }),
    {
      onSuccess: (data) => {
        let totalBins = 0;
        data?.ptl?.waves?.map((item) => {
          totalBins = totalBins + item.slotCount;
        });
        let totalWavesEmptyBins = 0;
        data?.ptl?.waves?.map((item) => {
          totalWavesEmptyBins =
            totalWavesEmptyBins + (data?.ptl?.availableSlotCount - item.slotCount);
        });

        setStatistics({
          totalWaves: data?.ptl?.waveCount,
          totalBins: totalBins,
          finalWavesBins: data?.ptl?.waves[data?.ptl?.waves.length - 1].slotCount,
          totalWavesEmptyBins: totalWavesEmptyBins,
        });
      },
    }
  );

  const [createSortingSession, { status: createSessionStatus }] = useMutation(
    ({ nodeId, tripIds }) =>
      fetch({
        url: 'wms-sorting/session',
        method: 'POST',
        params: {
          nodeId: nodeId,
        },
        data: {
          tripIds: tripIds,
        },
      }),
    {
      onSuccess: () => {
        refetchSortSession();
      },
    }
  );

  const [deleteSortSession, { status: delSessionStatus }] = useMutation(
    ({ sessionId, whId }) =>
      fetch({
        url: `wms-sorting/session/${sessionId}`,
        method: 'DELETE',
        headers: { 'x-wh-id': whId },
        params: { whId },
      }),
    {
      onSuccess: () => {
        refetchSortSession();
      },
    }
  );

  function onDateFilterChanged(dates) {
    if (dates.length) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(initialDates[0]);
      setEndDate(initialDates[1]);
    }
  }

  return (
    <div>
      {isFetchingSort || createSessionStatus === 'loading' || delSessionStatus === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}
      {/* Sort list section with filter header and pagination */}
      <div className="flex flex-col w-full h-full">
        <SortSessionList
          sessionList={state?.filteredSessionList}
          onFiltersChange={onDateFilterChanged}
          onFilterCancelled={() => onDateFilterChanged([])}
          onDeleteSession={(sessionId) => {
            deleteSortSession({ sessionId, whId: branchId });
          }}
        />
      </div>
      {/* Create sorting session fab button */}
      <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            setSortSessionDialogVisibility(true);
          }}
        >
          <AddIcon />
        </Fab>
      </div>

      {/* create Sort session dialog */}
      <Modal
        size="sm"
        overflow={true}
        show={sortSessionDialogVisibility}
        onHide={() => {
          setSortSessionDialogVisibility(false);
          setStatistics({});
        }}
      >
        <Modal.Body>
          {isOrderBased ? (
            <OrderBasedSortCreate
              onCancelPressed={() => {
                setSortSessionDialogVisibility(false);
              }}
            />
          ) : (
            <SortSessionDialogContent
              tripList={state?.tripList?.length ? state?.tripList : []}
              statisticsData={statistics}
              onDateChanged={(data) => {
                getTrips({
                  nodeId: branchId,
                  startDate: data[0],
                  endDate: data[data.length - 1],
                });
              }}
              onChangeTrips={(arr) => {
                const ids = arr?.map((item) => {
                  return item.value;
                });
                getStatistics({ nodeId: branchId, tripIds: ids });
              }}
              onCreateSessionCLicked={(ids) => {
                //updateProgress(true);
                createSortingSession({ nodeId: branchId, tripIds: ids });
                setSortSessionDialogVisibility(false);
                setStatistics({});
              }}
              onCancelPressed={() => {
                setSortSessionDialogVisibility(false);
                setStatistics({});
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
