import { usePickingTrips, useSessionParams } from 'apiHooks/wmsHooks';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import React, { useCallback, useEffect, useState } from 'react';
import { useBranchId, useSnackbar } from 'utils/customHooks';
import { RenderButton } from 'wms/bins/bins';
import { Trips } from './trips';
import {
  useCockpitInbound,
  useCockpitMutations,
  useCockpitOutbound,
  useCockpitReturns,
} from './useCockpit';

export const useCockpitTabs = ({ dateRange }) => {
  const [activeKey, setActiveKey] = useState(0);
  const branchId = useBranchId();

  const [dialogVisibility, setDialogVisibility] = useState(false);

  const showDialog = () => setDialogVisibility(true);
  const hideDialog = () => setDialogVisibility(false);

  const [session, setSession] = useState({});

  const onSelectSession = (x) => setSession(x);
  const onChangeTab = (key) => {
    setSession({});
    setActiveKey(key);
  };

  const {
    inboundForceComplete,
    outboundForceComplete,
    returnsForceComplete,
    downloadASNSample,
    downloadReturnsSample,
  } = useCockpitMutations({ dateRange });

  const isFetching = false;
  const [startDate, endDate] = dateRange;
  const query = useSessionParams({ createdAtWindowStart: startDate, createdAtWindowEnd: endDate });
  const { data: tripList } = usePickingTrips({ nodeId: branchId, startDate, endDate });
  const { inboundSessions, inboundTrips, refetchInbound } = useCockpitInbound({ query });
  const { outboundSessions, outboundTrips, refetchOutbound } = useCockpitOutbound({ query });
  const { returnsSessions, returnsTrips, refetchReturns } = useCockpitReturns({ query });

  const triggerRefetch = useCallback(() => {
    refetchInbound();
    refetchReturns();
    refetchOutbound();
  }, [refetchInbound, refetchReturns, refetchOutbound]);
  useEffect(() => {
    const interval = setInterval(() => triggerRefetch(), 5 * 1000);
    return () => clearInterval(interval);
  }, [triggerRefetch]);

  const tabConfigs = [
    {
      sessions: inboundSessions,
      buttonConfig: {
        title: 'Complete Inbound Session',
        onClick: () => inboundForceComplete(session.value),
      },
      tripsComponent: <Trips title="" trips={inboundTrips} />,
      createSessionComponent: (
        <CreateSessionComponent
          downloadLabel="Download Sample ASN"
          downloadCallback={downloadASNSample}
          uploadLabel="Create Inbound Session"
          uploadUri="wms/inbound/session/xlsx"
          uploadCallback={triggerRefetch}
        />
      ),
    },
    {
      sessions: outboundSessions,
      buttonConfig: {
        title: 'Complete Outbound Session',
        onClick: () => outboundForceComplete(session.value),
      },
      tripsComponent: <Trips title="Trip/Order" trips={outboundTrips} />,
      createSessionComponent: (
        <div>
          <RenderButton
            onClick={showDialog}
            button={{
              name: 'Create Outbound Session',
              className: 'h-8 font-bold',
            }}
          />
        </div>
      ),
    },
    {
      sessions: returnsSessions,
      buttonConfig: {
        title: 'Complete Returns Session',
        onClick: () => returnsForceComplete(session.value),
      },
      tripsComponent: <Trips title="Trip" trips={returnsTrips} />,
      createSessionComponent: (
        <CreateSessionComponent
          downloadLabel="Download Returns Sample"
          downloadCallback={downloadReturnsSample}
          uploadLabel="Create Returns Session"
          uploadUri="wms/cl_returns/session/xlsx"
          uploadCallback={triggerRefetch}
        />
      ),
    },
  ];
  return {
    tabConfigs,
    tripList,
    isFetching,
    activeKey,
    dialogVisibility,
    session,
    onSelectSession,
    hideDialog,
    onChangeTab,
  };
};

const CreateSessionComponent = ({
  downloadLabel,
  downloadCallback,
  uploadLabel,
  uploadCallback,
  uploadUri,
}) => {
  const [notif] = useSnackbar();
  const branchId = useBranchId();

  return (
    <div className="flex flex-row items-center">
      <div>
        <RenderButton
          onClick={downloadCallback}
          button={{ name: downloadLabel, className: 'h-8 font-bold' }}
        />
      </div>
      <Uploader
        id={'inbound_session'}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        url={uploadUri}
        headers={{ 'x-wh-id': branchId }}
        onSuccess={() => {
          notif('Uploaded successfully', { variant: 'success' });
          uploadCallback();
        }}
        render={(progress, apiInProgress) => (
          <DefaultUploaderRender
            tooltip={uploadLabel}
            progress={progress}
            apiInProgress={apiInProgress}
            icon={<RenderButton button={{ name: uploadLabel, className: 'h-8 font-bold' }} />}
          />
        )}
      />
    </div>
  );
};
