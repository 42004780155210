import produce from 'immer';

export const actions = {
  INIT: 'INIT',
  UPDATE_TRIPS: 'UPDATE_TRIPS',
};

const sortingReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        return {
          ...data,
          actualSessionList: data,
          filteredSessionList: data,
          tripList: [],
        };
      }

      case actions.UPDATE_TRIPS: {
        const dateObjArray = data.map((item) => ({
          ...item,
          dateObj: new Date(item.date),
        }));
        const sortedTripList = dateObjArray.slice().sort((a, b) => a.dateObj - b.dateObj);
        draft.tripList = sortedTripList;
        break;
      }

      default:
        return state;
    }
  });
};

export default sortingReducer;
