import React from 'react';
import Save from '@material-ui/icons/SaveRounded';
import Download from '@material-ui/icons/SaveAltRounded';
import Lock from '@material-ui/icons/LockOpenRounded';
import Locked from '@material-ui/icons/LockRounded';
import VpnKey from '@material-ui/icons/VpnKey';

export { Save, Download, Lock, Locked, VpnKey };

export const Upload = (props) => <Download style={{ transform: 'rotate(180deg)' }} {...props} />;
