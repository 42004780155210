import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DateTimePicker from 'components/inputs/DateTimePicker';
import React from 'react';
import Select from 'react-select';
import { useEWayBillHook } from './useLoading';

export function EwaybillDialogContent(props) {
  const {
    tripOptions,
    selectedTrips,
    config,
    initState,
    state,
    setSelectedTrips,
    onChange,
    setState,
    downloadBill,
  } = useEWayBillHook(props);

  return (
    <div className={`${tripOptions?.length >= 5 ? 'h-screen' : 'h-auto'} flex flex-col`}>
      {tripOptions?.length ? (
        <Select
          className="mt-5 z-20"
          placeholder="Select your trips"
          isMulti
          value={selectedTrips}
          closeMenuOnSelect={false}
          options={tripOptions}
          onChange={setSelectedTrips}
        />
      ) : null}

      <div className="container mt-5 py-2 rounded-md md:rounded-md bg-white shadow-md md:shadow-md">
        {config.map((c) => (
          <FormControl key={c.id} margin="normal" required fullWidth>
            <TextField
              id={c.id}
              label={c.label}
              variant="outlined"
              value={state[c.id] || ''}
              size="small"
              onChange={(e) => onChange(c.id, e.target.value)}
            />
          </FormControl>
        ))}
        <FormControl margin="normal" required fullWidth>
          <DateTimePicker
            twclass="sm:w-5/12 md:w-4/12 lg:w-4/12 xl:w-4/12"
            placeholder="Transporter Doc Date"
            onChange={(dateRange) => setState((p) => ({ ...p, transDocDate: dateRange[0] }))}
            maxDays={1}
          />
        </FormControl>
      </div>

      {tripOptions?.length ? (
        <div className="flex flex-row space-x-2 mt-5 justify-center items-center">
          <Button color="primary" variant="contained" onClick={() => downloadBill()}>
            Download E-way Bill Json
          </Button>
          <Button color="primary" variant="contained" onClick={() => downloadBill('support_docs')}>
            Download Supporting Doc
          </Button>

          <Button
            color="secondary"
            variant="contained"
            className="text-white"
            onClick={() => {
              setState((p) => ({ ...initState, transDocDate: p.transDocDate }));
              setSelectedTrips([]);
            }}
          >
            Reset For New E-way Bill
          </Button>
        </div>
      ) : (
        <p className="mt-10 text-center">No trips available</p>
      )}
    </div>
  );
}
