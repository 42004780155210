import { useWMSDownloadFile } from 'apiHooks/useFileOps';
import {
  useInboundCockpitTrips,
  useOutboundCockpitTrips,
  useReturnsCockpitTrips,
  useSessionComplete,
} from 'apiHooks/wmsHooks';

import { useBranchId } from 'utils/customHooks';
import { decodeSessionCode } from 'utils/utils';

const inboundCols = [
  { label: 'Receiving', key: 'receiveState', pkey: 'receiveProgress' },
  { label: 'Putaway', key: 'putawayState', pkey: 'putawayProgress' },
  { label: 'Goods Receipt', key: 'putawayState', pkey: undefined },
];

const outboundCols = [
  { label: 'Replenishment', key: 'replenState', pkey: 'replenProgress' },
  { label: 'Picking', key: 'pickState', pkey: 'pickProgress' },
  { label: 'Sorting', key: 'sortState', pkey: 'sortProgress' },
  { label: 'Loading', key: 'loadState', pkey: 'loadProgress' },
  { label: 'Goods Issue', key: 'loadState', pkey: undefined },
];
const returnsCols = [
  { label: 'Receiving', key: 'receiveState', pkey: 'receiveProgress' },
  { label: 'Putaway', key: 'putawayState', pkey: 'putawayProgress' },
  { label: 'RRN', key: 'putawayState', pkey: undefined },
];
const tab = {
  inbound: 'inbound',
  outbound: 'outbound',
  cl_returns: 'cl_returns',
};

const getCols = (cols, x) => cols.map((c) => columnMapper(x, c));
const columnMapper = (x, c) => ({
  ...c,
  state: x[c.key] || 'NONE',
  percent: c.pkey && x[c.key] ? x[c.pkey] | 0 || 0 : 'NONE',
});

const getSessionOptions = (data, cols) => {
  const sessionReducer = (a, c) => ({ ...a, [c.sessionId]: c.sessionCode });

  const obj =
    data
      ?.filter((x) => cols.some((c) => x[c.key] !== 'COMPLETED' && !!x[c.key]))
      .reduce(sessionReducer, {}) || {};
  return Object.entries(obj).map(([id, code]) => ({ value: id, label: decodeSessionCode(code) }));
};

export const useCockpitInbound = ({ query }) => {
  const branchId = useBranchId();
  const inboundResult = useInboundCockpitTrips({ branchId, query });
  const { data: inbound, refetch } = inboundResult;

  const inboundTrips = inbound?.map((x) => ({
    ...x,
    tripId: x.vehicleNo,
    tripCode: x.vehicleNo, //`Trip: ${x.vehicleNo} ASN: ${x.asnNo}`,
    cols: getCols(inboundCols, x),
  }));
  const inboundSessions = getSessionOptions(inbound, inboundCols);
  return { inboundTrips, inboundSessions, refetchInbound: refetch };
};
export const useCockpitOutbound = ({ query }) => {
  const branchId = useBranchId();
  const outboundResult = useOutboundCockpitTrips({ branchId, query });
  const { data: outbound, refetch } = outboundResult;

  const outboundTrips = outbound?.map((x) => ({
    ...x,
    cols: getCols(outboundCols, x),
  }));

  const outboundSessions = getSessionOptions(outbound, outboundCols);
  return { outboundTrips, outboundSessions, refetchOutbound: refetch };
};
export const useCockpitReturns = ({ query }) => {
  const branchId = useBranchId();
  const returnsResult = useReturnsCockpitTrips({ branchId, query });
  const { data: returns, refetch } = returnsResult;
  const returnsTrips = returns?.map((x) => ({
    ...x,
    cols: getCols(returnsCols, x),
  }));

  const returnsSessions = getSessionOptions(returns, returnsCols);
  return { returnsTrips, returnsSessions, refetchReturns: refetch };
};
export const useCockpitMutations = () => {
  const branchId = useBranchId();
  const [downloadFile] = useWMSDownloadFile({ branchId });

  const [sessionForceComplete] = useSessionComplete({ branchId });

  const downloadASNSample = () => downloadFile({ url: '/wms/inbound/session/sample', params: {} });
  const downloadReturnsSample = () =>
    downloadFile({ url: '/wms/cl_returns/session/sample', params: {} });
  function inboundForceComplete(sessionId) {
    sessionForceComplete({ sessionId, type: tab.inbound });
  }
  function outboundForceComplete(sessionId) {
    sessionForceComplete({ sessionId, type: tab.outbound });
  }

  function returnsForceComplete(sessionId) {
    sessionForceComplete({ sessionId, type: tab.cl_returns });
  }

  return {
    inboundForceComplete,
    outboundForceComplete,
    returnsForceComplete,
    downloadASNSample,
    downloadReturnsSample,
  };
};
