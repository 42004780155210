import LinearProgress from '@material-ui/core/LinearProgress';
import { useSortingSessionData } from 'apiHooks/wmsHooks';
import Link from 'components/Link';
import React from 'react';
import { useBranchId } from 'utils/customHooks';
import { getWaveWiseTasks } from './SortingTasks';

export default function SortingWaves(props) {
  const { sessionCode } = props;
  const branchId = useBranchId();
  const { data } = useSortingSessionData({ sessionCode, branchId });

  if (!data) {
    return (
      <LinearProgress
        classes={{
          colorPrimary: 'bg-green-300',
        }}
      />
    );
  }
  const waveWiseTasks = getWaveWiseTasks(data);
  return (
    <div className="container">
      <div className="flex flex-col w-full h-full p-5">
        <div className="flex-1">
          <p className="text-base md:text-lg antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            {sessionCode} Waves
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4">
          {Object.entries(waveWiseTasks)?.map(([wave, tasks]) => (
            <WaveItem key={wave} sessionCode={sessionCode} wave={wave} tasks={tasks} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function WaveItem(props) {
  const { wave, tasks, sessionCode } = props;
  const progress = tasks.reduce(
    (a, c) => {
      a.done += +c.progress.done;
      a.target += +c.progress.target;
      return a;
    },
    { done: 0, target: 0 }
  );

  const getMetrics = () => {
    return `Done : ${progress.done} Pending : ${progress.target - progress.done}`;
  };

  const getStatus = () => {
    const completed = tasks.every((t) => t.state === 'COMPLETED');
    return completed ? 'Completed' : 'Pending';
  };

  const isWaveCompleted = progress.done === progress.target;

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        isWaveCompleted ? 'border-success-green' : 'border-background'
      } m-5 p-3`}
    >
      <div className="flex flex-row">
        <div className="flex-2 flex-col flex-grow">
          <Link to={`/wms/outbound/sorting/tasks/${sessionCode}/${wave}`}>
            <p className="text-base md:text-base font-bold md:font-bold">Wave {+wave + 1}</p>
            <div className="flex flex-col">
              <p className="text md:text-xs font-medium md:font-medium">{getMetrics()}</p>
              <p className="text md:text-xs font-medium md:font-medium">{getStatus()}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
